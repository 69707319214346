import { ObjectSchema } from "yup";
import TextBoxField from "../../common/components/Field/TextBoxField";
import { MultiSelectField } from "../../common/components/Field/MultiSelectField";
import { AdditionalFieldType, AdditionalFieldViewModel } from "../model/AdditionalFieldViewModel";

export function ModuleServiceRequestAdditionalFields(props: {
    values: AdditionalFieldViewModel[] | undefined,
    fieldName: "globalAdditionalFields" | "additionalFieldsByCategory",
    validationSchema: ObjectSchema<any>,
    isMandatoryText: string,
    fieldMaxLengthText: string,
}) {
    return (
        <div>
            {props.values && props.values.length > 0
                ? props.values.map((additionalField, index) => {
                    return (
                        <div className="request-form__additional-field" key={additionalField.id}>
                            {additionalField.type?.id === AdditionalFieldType.Text
                                ? <TextBoxField
                                    fieldName={`${props.fieldName}.${index}.textValue`}
                                    label={`${additionalField.name}*`}
                                    title={additionalField.name}
                                    placeholder={props.isMandatoryText}
                                />
                                : <MultiSelectField
                                    fieldName={`${props.fieldName}.${index}.optionValue`}
                                    label={`${additionalField.name}*`}
                                    title={additionalField.name}
                                    validationSchema={props.validationSchema}
                                    dropdownProps={{
                                        data: additionalField.options,
                                        dataItemKey: "id",
                                        textField: "name",
                                        autoClose: false,
                                        filterable: true,
                                    }}
                                    placeholder={props.isMandatoryText}
                                />}
                        </div>
                    );
                })
                : null
            }
        </div>
    );
}