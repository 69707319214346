import { CaptchaV2Token, CaptchaV3Result, ClientSettings, CommonData, IntlMessages, Language } from '../model/commonModel';
import { RequestFormData } from '../../servicerequest/model/requestFormModel';
import { FeedbackFormData } from '../../feedback/model/feedbackModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export function createCommonData(formData: RequestFormData | FeedbackFormData): CommonData {
    return {
        PrivacyPolicyUrl: formData.PrivacyPolicyUrl,
        ProductName: formData.ProductName,
    }
}

export interface CommonState {
    isInitialFetching: boolean;
    isFetching: boolean;
    isSubmitting: boolean;
    isServiceRequestModuleInUse: boolean;
    languages: Array<Language>;
    selectedLanguage: Language | null,
    localizedMessages: IntlMessages | null,
    clientSettings: ClientSettings | null,
    commonData: CommonData | null,
    captchaV3Result: CaptchaV3Result | null,
    captchaV2Token: CaptchaV2Token | null
}

export const initialState: CommonState = {
    isInitialFetching: true,
    isFetching: true,
    isSubmitting: false,
    isServiceRequestModuleInUse: false,
    languages: [],
    selectedLanguage: null,
    localizedMessages: null,
    clientSettings: null,
    commonData: null,
    captchaV3Result: null,
    captchaV2Token: null
};

export const commonSlice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {
        resetAppState: (state) => {
            state.isSubmitting = false;
        },
        setInitialFetching: (state, action: PayloadAction<boolean>) => {
            state.isInitialFetching = action.payload;
        },
        fetchStart: (state) => {
            state.isFetching = true;
        },
        fetchEnd: (state) => {
            state.isFetching = false;
        },
        setServiceRequestModuleInUse: (state, action: PayloadAction<boolean>) => {
            state.isServiceRequestModuleInUse = action.payload;
        },
        selectLanguage: (state, action: PayloadAction<Language>) => {
            state.selectedLanguage = action.payload;
        },
        setLanguageCodes: (state, action: PayloadAction<Array<Language>>) => {
            state.languages = action.payload;
        },
        setLocalizedMessages: (state, action: PayloadAction<IntlMessages>) => {
            state.localizedMessages = action.payload;
        },
        setClientSettings: (state, action: PayloadAction<ClientSettings>) => {
            state.clientSettings = action.payload;
        },
        setCommonData: (state, action: PayloadAction<CommonData>) => {
            state.commonData = action.payload;
        },
        setCaptchaV3Token: (state, action: PayloadAction<CaptchaV3Result | null>) => {
            state.captchaV3Result = action.payload;
        },
        setCaptchaV2Token: (state, action: PayloadAction<CaptchaV2Token | null>) => {
            state.captchaV2Token = action.payload;
        },
        resetCaptchaTokens: (state) => {
            state.captchaV2Token = null;
            state.captchaV3Result = null;
        },
    }
})

export const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;