import { all } from 'redux-saga/effects';
import { feedbackFormSagas } from '../../feedback/sagas/feedbackFormSaga';
import { moduleRequestFormSagas } from '../../moduleservicerequest/sagas/moduleRequestFormSaga';
import { requestFormSagas } from '../../servicerequest/sagas/requestFormSaga';
import { commonSagas } from './commonSaga';

export function* rootSaga() {
    yield all({
        ...commonSagas,
        ...moduleRequestFormSagas,
        ...requestFormSagas,
        ...feedbackFormSagas
    });
}
