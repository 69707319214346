import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { classNames } from '@progress/kendo-react-common';
import { FieldProps, getIn } from 'formik';

export const isValid = (fieldName: string, fieldProps: FieldProps<any>) => !getIn(fieldProps.form.errors, fieldName) || !getIn(fieldProps.form.touched, fieldName);

export interface IValidatorProps {
    valid: boolean;
    fieldProps: FieldProps<any>;
    fieldName: string;
    className?: string;
}

export function Validator(props: IValidatorProps) {
    const iconClassNames = classNames({
        "form__warning": true,
        [`${props.className}`]: props.className != null
    });

    const errorMessage: any = getIn(props.fieldProps.form.errors, props.fieldName) || '';

    return (
        <>
            {
                props.valid
                    ? null
                    :<Tooltip anchorElement="target" position="left" className="error-info">
                        <div className={iconClassNames} title={errorMessage}>
                            <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                        </div>
                     </Tooltip>
            }
        </>
    );
}