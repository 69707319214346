import React from 'react';
import { connect } from 'react-redux';
import { Location, Action } from 'history';
import { History } from 'history';
import Loading from '../../common/components/Loading';
import * as commonSelectors from '../../common/selectors/commonSelectors';
import { Localization } from '../../common/components/Localization';
import { Language } from '../../common/model/commonModel';
import RequestFormWrapper from '../../servicerequest/components/RequestFormWrapper';
import { ModuleRequestFormWrapper } from '../../moduleservicerequest/components/ModuleRequestFormWrapper';
import { initAsyncAction } from '../sagas/commonSaga';
import { commonActions } from '../reducers/commonReducers';
import { ApplicationState } from '../../store/configureStore';

export interface ServiceRequestProps {
    isInitialFetching: boolean;
    initAsync: () => void;
    resetAppState: () => void;
    history: History;
    selectedLanguage: Language;
    isServiceRequestModuleInUse: boolean;
}

class ServiceRequest extends React.Component<ServiceRequestProps> {
    constructor(props: ServiceRequestProps) {
        super(props);

        this.updatePastLocations = this.updatePastLocations.bind(this);
    }

    private updatePastLocations(location: Location, action: Action) {
        switch (action) {
            case 'POP': {
                this.props.resetAppState();
                break;
            }
            default:
        }
    }

    componentDidMount() {
        this.props.initAsync();

        this.props.history.listen(this.updatePastLocations);
    }

    render() {
        return (
            this.props.isInitialFetching
                ? <Loading />
                :
                <Localization kendoCode={this.props.selectedLanguage.KendoCode}>
                    {
                        this.props.isServiceRequestModuleInUse ?
                            <ModuleRequestFormWrapper/> :
                            <RequestFormWrapper />
                    }
                </Localization>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        initAsync: () => {
            dispatch(initAsyncAction("servicerequest"));
        },
        resetAppState: () => {
            dispatch(commonActions.resetAppState());
        }
    };
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        isInitialFetching: state.common.isInitialFetching,
        selectedLanguage: commonSelectors.selectedLanguage(state),
        isServiceRequestModuleInUse: state.common.isServiceRequestModuleInUse,
        ...ownProps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
