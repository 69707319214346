import { FieldArrayRenderProps } from "formik";
import i18next from "i18next";

export function resource(key: string) {
    return i18next.t(key);
}

export function replaceFieldArrayValues<T>(fieldArrayRenderProps: FieldArrayRenderProps, items: T[], howManyItemsToClear: number) {
    // Remove fields from field array
    while (howManyItemsToClear > 0) {
        fieldArrayRenderProps.pop();
        howManyItemsToClear -= 1;
    }

    // Add new items to field array
    items.forEach(item => {
        fieldArrayRenderProps.push(item);
    });
}

export function getTextBrightness(colorString: string) {
    const vals = colorString.substring(colorString.indexOf('(') + 1, colorString.length - 1).split(', ');

    const red = Number(vals[0]);
    const green = Number(vals[2]);
    const blue = Number(vals[3]);

    const brightness = ((red * 299) + (green * 587) + (blue * 114)) / 1000;

    return brightness;
}