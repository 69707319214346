import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Ripple } from '@progress/kendo-react-ripple';
import { Formik, FormikProps, FormikHelpers, Form, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from 'yup';
import UploadDocuments from '../../common/components/UploadDocuments';
import { CaptchaV2Token, CaptchaV3Result, ClientSettings, Language } from '../../common/model/commonModel';
import TitleBar from '../../common/components/TitleBar';
import TextAreaField from '../../common/components/Field/TextAreaField';
import { DropDownListField } from '../../common/components/Field/DropDownListField';
import * as moduleRequestFormSelectors from '../selectors/moduleRequestFormSelectors';
import TextBoxField from '../../common/components/Field/TextBoxField';
import { CheckboxField } from '../../common/components/Field/CheckboxField';
import CustomCookieConsent from '../../common/components/CustomCookieConsent';
import { resource } from '../../common/utils';
import { ModuleRequestFormData, ModuleRequestFormModel } from '../model/ModuleRequestFormData';
import { ModuleServiceRequestGlobalAdditionalFields } from './ModuleServiceRequestGlobalAdditionalFields';
import { ModuleServiceRequestAdditionalFieldsByCategory } from './ModuleServiceRequestAdditionalFieldsByCategory';
import { AdditionalFieldType } from '../model/AdditionalFieldViewModel';
import { ApplicationState } from '../../store/configureStore';
import { loadModuleRequestFormDataAsyncAction, moduleRequestFormReCaptchaV3VerificationAsyncAction, moduleRequestFormSubmitAsyncAction, setModuleRequestFormReCaptchaV2TokenAsyncAction } from '../sagas/moduleRequestFormSaga';
import ReCAPTCHA from "react-google-recaptcha";
import * as commonSelectors from '../../common/selectors/commonSelectors';

export interface ModuleRequestFormProps {
    requestFormGuid: string;
    requestFormData: ModuleRequestFormData;
    clientSettings: ClientSettings;
    reCaptchaV3Result: CaptchaV3Result | null,
    reCaptchaV2Token: CaptchaV2Token | null,
    isMandatoryText: string;
    fieldMaxLengthText: string;
    fieldContainsIllegalCharacters: string;
    requestFormSubmit: (values: any) => void;
    loadRequestFormDataAsync: (language: Language) => void;
    reCaptchaV3Verification: () => void;
    setReCaptchaV2Token: (token: string | null) => void;
}

export const validateInvalidCharacters = (value: string | undefined) => {
    if (!value) return true;

    const illegalCharacters: RegExp = /[<>/%]+/g; // regexp needs to be recreated always for the handling to go correctly (calling will advance the lastIndex)
    const hasIllegalChars = illegalCharacters.test(value);
    return !hasIllegalChars;
}

// copied from manager GMValidator.ts isValidEmail() 11.6.2019
export const emailRegExp: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneNumberRegExp: RegExp = /^[\d+][ \d-]+[\d]+$/;

const addtionalFieldSchema = (props: ModuleRequestFormProps) =>
    Yup.array().of(
        Yup.object().shape({
            type: Yup.object().shape({
                id: Yup.mixed<AdditionalFieldType>(),
                name: Yup.string()
            }),
            textValue: Yup.string().nullable().when("type.id", {
                is: AdditionalFieldType.Text,
                then: Yup.string().trim().required(props.isMandatoryText).max(5000, props.fieldMaxLengthText),
            }),
            optionValue: Yup.array().nullable().when("type.id", {
                is: AdditionalFieldType.MultiSelectOptions,
                then: Yup.array().min(1, props.isMandatoryText).required(props.isMandatoryText),
            })
        })
    );

const addNewServiceSchema = (props: ModuleRequestFormProps) =>
    Yup.object().shape({
        description: Yup.string()
            .test('has-invalid-characters', props.fieldContainsIllegalCharacters, validateInvalidCharacters)
            .trim()
            .required(props.isMandatoryText)
            .max(5000, props.fieldMaxLengthText),
        contactName: Yup.string()
            .test('has-invalid-characters', props.fieldContainsIllegalCharacters, validateInvalidCharacters)
            .trim()
            .required(props.isMandatoryText)
            .max(256, props.fieldMaxLengthText),
        contactEmail: Yup.string()
            .matches(emailRegExp, { excludeEmptyString: true, message: resource("common-emailinvalid") }) // use this instead of .email() because we dont want to support ��� etc
            .required(props.isMandatoryText)
            .max(256, props.fieldMaxLengthText),
        contactPhone: Yup.string()
            .matches(phoneNumberRegExp, { excludeEmptyString: true, message: resource("common-phonenumberinvalid") })
            .max(30, props.fieldMaxLengthText)
            .required(props.isMandatoryText),
        selectedCategoryType: Yup.object().nullable().required(props.isMandatoryText),
        selectedServiceArea: Yup.object().nullable().required(props.isMandatoryText),
        globalAdditionalFields: addtionalFieldSchema(props),
        additionalFieldsByCategory: addtionalFieldSchema(props),
    });

class ModuleRequestForm extends React.Component<ModuleRequestFormProps> {
    constructor(props: ModuleRequestFormProps) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.getValidRecaptchaTokenWithVersionInfo = this.getValidRecaptchaTokenWithVersionInfo.bind(this);
    }

    private handleFormSubmit(submit: () => Promise<void>) {
        if (this.props.clientSettings.disableCaptcha || this.getValidRecaptchaTokenWithVersionInfo()) {
            // Token exists or captcha is disabled, submit form
            submit();
        }
        else {
            // Get token first and submit if result is valid
            this.props.reCaptchaV3Verification();
        }
    }

    private getValidRecaptchaTokenWithVersionInfo() {
        if (this.props.reCaptchaV3Result?.Valid && this.props.reCaptchaV3Result.Token) {
            return this.props.reCaptchaV3Result.Token + "&v3";
        }
        else if (this.props.reCaptchaV2Token?.Token) {
            return this.props.reCaptchaV2Token.Token + "&v2";
        }

        return null;
    }

    render() {
        let data = this.props.requestFormData;
        let hideCategorySelection = data.categories?.length <= 1;
        return (
            <Formik
                enableReinitialize={false}
                initialValues={{
                    selectedCategoryType: hideCategorySelection && data.categories?.length === 1 ? data.categories[0] : null,
                    selectedServiceArea: null,
                    globalAdditionalFields: [],
                    additionalFieldsByCategory: [],
                    description: "",
                    contactName: "",
                    contactPhone: "",
                    contactEmail: "",
                    subscribed: true,
                    files: []
                }}
                validate={(values: any) => {
                    try {
                        validateYupSchema<any>(values, addNewServiceSchema(this.props), true, { data });
                    } catch (err) {
                        return yupToFormErrors(err);
                    }

                    return {};
                }}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                    let token = this.getValidRecaptchaTokenWithVersionInfo();
                    this.props.requestFormSubmit({
                        requestFormGuid: this.props.requestFormGuid,
                        captchaToken: token,
                        ...values
                    });
                }}
            >
                {(formikProps: FormikProps<ModuleRequestFormModel>) => {
                    // file upload status must be Uploaded
                    let uploadReady = formikProps.values.files.every(file => file.status === 4);
                    let selectedCategory = formikProps.values.selectedCategoryType;
                    let serviceAreas = selectedCategory
                        ? selectedCategory.serviceAreas
                        : [];
                    let hideServiceAreaSelection = serviceAreas.length <= 1;
                    let showRecaptchaV2 = this.props.reCaptchaV3Result?.ShowReCaptchaV2 && !formikProps.isSubmitting;
                    let recaptchaV2Token = this.props.reCaptchaV2Token?.Token;

                    let themeColor = getComputedStyle(document.body).getPropertyValue('--themeColor');
                    let themebackground = getComputedStyle(document.body).getPropertyValue('--themeBackground');
                    let subtitleColor = (themeColor == "#ffff") ? "#rgba(0,0,0, .87)" : themebackground;

                    useEffect(() => {
                        // If only one serviceArea preselect it
                        if (serviceAreas?.length === 1) {
                            formikProps.setFieldValue("selectedServiceArea", serviceAreas[0]);
                        }
                        // Check if service area selection is found in a new service area list. If not found clear selection.
                        else if (serviceAreas?.length > 1) {
                            const serviceAreaId = formikProps.values.selectedServiceArea?.id;
                            const serviceAreaExists = serviceAreas.some(sa => sa.id === serviceAreaId);
                            if (!serviceAreaExists) {
                                formikProps.values.selectedServiceArea = null;
                            }
                        }
                        else {
                            formikProps.values.selectedServiceArea = null;
                        }
                    }, [serviceAreas]);

                    useEffect(() => {
                        if (this.props.reCaptchaV3Result?.Valid == true) {
                            formikProps.submitForm();
                        }
                    }, [this.props.reCaptchaV3Result]);

                    return (
                        <Ripple>
                        <div className="main">
                            <div className="form-container">
                                <TitleBar title={resource("ppp-newservice")} objectName={this.props.requestFormData.location ?? resource("unknown-object")} reloadFormDataAction={this.props.loadRequestFormDataAsync} />
                                <Form className="k-form k-form-md form">
                                    
                                        <div className="request-form__area">
                                            <TextAreaField
                                                autoFocus={true}
                                                fieldName="description"
                                                title={resource("ppp-portlet-column-description")}
                                                label={resource("ppp-portlet-column-description")}
                                                isRequired
                                                placeholder={this.props.requestFormData.descriptionInfoText}
                                                isPlaceholderEnabled={this.props.requestFormData.isDescriptionInfoTextInUse}
                                            />
                                            <UploadDocuments
                                                requestFormGuid={this.props.requestFormGuid}
                                                imageTypeList={this.props.requestFormData.fileUploadTexts.imageTypeList}
                                                fileTypeList={this.props.requestFormData.fileUploadTexts.fileTypeList}
                                                fieldName="files"
                                                setFieldValue={formikProps.setFieldValue} />
                                        </div>

                                        <DropDownListField
                                            fieldName="selectedCategoryType"
                                            hidden={hideCategorySelection}
                                            label={resource("title-category")}
                                            isRequired
                                            dropdownProps={{
                                                data: data.categories,
                                                textField: "name",
                                                dataItemKey: "id"
                                            }} />

                                        <DropDownListField
                                            fieldName="selectedServiceArea"
                                            label={resource("common-servicearea")}
                                            isRequired
                                            hidden={hideServiceAreaSelection}
                                            dropdownProps={{
                                                data: serviceAreas,
                                                textField: "name",
                                                dataItemKey: "id"
                                            }} />

                                        <div className="request-form__additional-fields">
                                            {data.additionalFields.length > 0
                                                ? <div
                                                    className="request-form-subtitle"
                                                    style={{ color: subtitleColor }}>
                                                    {resource('title-additional-fields')}
                                                </div>
                                                : null
                                            }
                                            <ModuleServiceRequestGlobalAdditionalFields
                                                form={formikProps.values}
                                                values={data}
                                                validationSchema={addNewServiceSchema(this.props)}
                                                isMandatoryText={this.props.isMandatoryText}
                                                fieldMaxLengthText={this.props.fieldMaxLengthText}
                                            />
                                            <ModuleServiceRequestAdditionalFieldsByCategory
                                                form={formikProps.values}
                                                values={selectedCategory}
                                                validationSchema={addNewServiceSchema(this.props)}
                                                isMandatoryText={this.props.isMandatoryText}
                                                fieldMaxLengthText={this.props.fieldMaxLengthText}
                                                setFieldValue={formikProps.setFieldValue}
                                                setFieldTouched={formikProps.setFieldTouched}
                                            />
                                        </div>

                                        <div className="request-form-contact-information">
                                            <div className="request-form-subtitle" style={{ color: subtitleColor }}>
                                                {resource("common-contacts")}
                                            </div>
                                            <TextBoxField
                                                fieldName="contactName"
                                                label={resource("common-name")}
                                                isRequired />
                                            <TextBoxField
                                                fieldName="contactPhone"
                                                label={resource("ppp-labelphonenumberheader")}
                                                isRequired />
                                            <TextBoxField
                                                fieldName="contactEmail"
                                                label={resource("common-email")}
                                                isRequired />
                                        </div>
                                        <CheckboxField
                                            fieldName="subscribed"
                                            label={resource("ppp-checkboxsendserviceemailsheader")} />

                                        <div className="request-form-recaptcha">
                                            {showRecaptchaV2 && (
                                                <ReCAPTCHA
                                                    sitekey={this.props.clientSettings.reCaptchaV2SiteKey}
                                                    onChange={this.props.setReCaptchaV2Token}
                                                    theme="light"
                                                    size='normal'
                                                />
                                            )}
                                        </div>

                                        <div className="form__submit-area-container k-d-flex k-justify-content-end">
                                            <Button
                                                themeColor={null}
                                                rounded={"full"}
                                                type="button"
                                                onClick={() => this.handleFormSubmit(formikProps.submitForm)}
                                                className="submit-button"
                                                size={"large"}
                                                disabled={formikProps.isSubmitting || !formikProps.isValid || !uploadReady || (showRecaptchaV2 && !recaptchaV2Token)}>
                                                {resource("common-send")}
                                            </Button>
                                        </div>
                                    
                                </Form>
                            </div>
                            <CustomCookieConsent />
                            </div>
                        </Ripple>
                    );
                }}
            </Formik>

        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestFormSubmit: (payload: any) => {
            dispatch(moduleRequestFormSubmitAsyncAction(payload));
        },
        loadRequestFormDataAsync: (payload: any) => {
            dispatch(loadModuleRequestFormDataAsyncAction(payload));
        },
        reCaptchaV3Verification: () => {
            dispatch(moduleRequestFormReCaptchaV3VerificationAsyncAction());
        },
        setReCaptchaV2Token: (token: string | null) => {
            dispatch(setModuleRequestFormReCaptchaV2TokenAsyncAction(token));
        }
    };
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        requestFormData: moduleRequestFormSelectors.requestFormData(state),
        requestFormGuid: moduleRequestFormSelectors.requestFormGuid(state),
        clientSettings: commonSelectors.clientSettings(state),
        reCaptchaV3Result: commonSelectors.reCaptchaV3Result(state),
        reCaptchaV2Token: commonSelectors.reCaptchaV2Token(state),
        isMandatoryText: resource("commonprocess-exchangeratevaluevalidationmessage"),
        fieldMaxLengthText: resource("common-fieldmaxlengthtext"),
        fieldContainsIllegalCharacters: resource("common-fieldcontainsillegalcharacters"),
        ...ownProps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleRequestForm);