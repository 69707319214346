import { FieldArray, FieldArrayRenderProps } from 'formik';
import { useEffect } from 'react';
import { ObjectSchema } from "yup";
import { replaceFieldArrayValues } from '../../common/utils';
import { ModuleRequestFormData, ModuleRequestFormModel } from '../model/ModuleRequestFormData';
import { ModuleServiceRequestAdditionalFields } from './ModuleServiceRequestAdditionalFields';

const globalAdditionalFieldsName = "globalAdditionalFields";

export function ModuleServiceRequestGlobalAdditionalFields(props: { 
    form: ModuleRequestFormModel,
    values: ModuleRequestFormData, 
    isMandatoryText: string,
    fieldMaxLengthText: string,
    validationSchema: ObjectSchema<any>, 
}) {    
    return (
        <>
            <FieldArray name={globalAdditionalFieldsName} render={(fieldArrayRenderProps: FieldArrayRenderProps) => {
                return (
                    <>
                        <ServiceRequestEditGlobalAdditionalFieldsState 
                            fieldArrayRenderProps={fieldArrayRenderProps}
                            form={props.form}
                            values={props.values}
                        />
                        
                        <ModuleServiceRequestAdditionalFields 
                            values={props.values?.additionalFields}
                            fieldName={globalAdditionalFieldsName}
                            isMandatoryText={props.isMandatoryText}
                            fieldMaxLengthText={props.fieldMaxLengthText}
                            validationSchema={props.validationSchema}
                        />
                    </>
                );
            }} />
        </>
    );
}

function ServiceRequestEditGlobalAdditionalFieldsState(props: { 
    form: ModuleRequestFormModel,
    values: ModuleRequestFormData, 
    fieldArrayRenderProps: FieldArrayRenderProps,
}) {
    const additionalFields = props.values?.additionalFields;

    useEffect(() => {
        replaceFieldArrayValues(props.fieldArrayRenderProps, additionalFields, props.form[globalAdditionalFieldsName]?.length);
    }, [additionalFields]);
    
    return null;
}