import 'raygun4js';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt, faGlobe, faCheckCircle, faSpinner, faExclamationCircle, faCamera, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { ConnectedRouter } from 'connected-react-router';

const history = createBrowserHistory();
const store = configureStore(history);

const rootElement = document.getElementById('root');

library.add(faMapMarkerAlt);
library.add(faGlobe);
library.add(faCheckCircle);
library.add(faSpinner);
library.add(faExclamationCircle);
library.add(faCamera);
library.add(faTimesCircle);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    rootElement);