import React from 'react';
import { Field, FieldProps } from 'formik';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Validator, isValid } from './Validator';
import { getLabel } from './TextBoxField';
import { FieldInfoText } from './FieldInfoText';
import { TextArea } from '@progress/kendo-react-inputs';

export interface TextAreaProps {
    fieldName: string;
    label: string;
    isRequired?: boolean;
    autoFocus?: boolean;
    title?: string;
    rows?: number;
    dir?: string;
    placeholder?: string;
    isPlaceholderEnabled?: boolean;
    info?: string;
}

class TextAreaField extends React.Component<TextAreaProps> {
    constructor(props: any) {
        super(props);
    }

    onBlur = (event: any, fieldProps: FieldProps<any>) => {
        fieldProps.form.handleBlur(event);
    }

    onChange = (event: any, fieldProps: FieldProps<any>) => {
        fieldProps.form.setFieldValue(this.props.fieldName, event.value);
    }

    render() {
        const label = getLabel(this.props.label, this.props.isRequired);

        return (
            <Field name={this.props.fieldName}>
                {(fieldProps: FieldProps<any>) => {
                    const valid = isValid(this.props.fieldName, fieldProps);
                    const placeholder = this.props.isPlaceholderEnabled
                        ? this.props.placeholder
                        : "";

                    return (
                        <div className="form__input-container k-relative k-form-field">
                            <FloatingLabel
                                label={label}
                                editorId={fieldProps.field.name}
                                editorValue={fieldProps.field.value}>
                                <TextArea
                                    {...fieldProps.field}
                                    id={this.props.fieldName}
                                    value={fieldProps.field.value} 
                                    valid={valid}
                                    onChange={
                                        (event: any) => this.onChange(event, fieldProps)
                                    }
                                    rows={this.props.rows || 4}
                                    dir={this.props.dir || "ltr"}
                                    data-testid="requestTextArea"
                                    placeholder={placeholder}
                                    autoFocus={this.props.autoFocus}
                                    onBlur={(event: any) => this.onBlur(event, fieldProps)}
                                />
                                <Validator
                                    valid={valid}
                                    fieldProps={fieldProps}
                                    fieldName={this.props.fieldName}
                                    className="form__warning--textarea" />
                                
                            </FloatingLabel>
                            <FieldInfoText
                                valid={valid}
                                fieldProps={fieldProps}
                                info={this.props.info} />
                        </div>
                    );
                }}
            </Field>
        );
    }
}
        
export default TextAreaField;