import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/configureStore';
import * as commonSelectors from '../selectors/commonSelectors';
import { resource } from '../utils';

export interface FooterProps {
    hideRecaptha: boolean;
    productName: string;
    policyText: string;
    policyUrl: string;
    termsOfServiceText: string;
    googleReCaptchaBranding: string;
}

export const Footer: React.SFC<FooterProps> = (props) => (
    <div className="footer k-absolute k-text-center">
        <div className="footer__gm-terms">
            <span className="footer__product-name">
                {props.productName}
            </span>
            |
            <a
                className="footer__privacy-policy"
                href={props.policyUrl}
                target="_blank"
                rel="noopener noreferrer"
            >
                {props.policyText}
            </a>
        </div>
        {
            !props.hideRecaptha
                ?
                <div
                    className="footer__captcha-terms"
                    dangerouslySetInnerHTML={{ __html: getCaptchaTerms(props) }}
                >
                </div>
                : null
        }
    </div>
);

const getCaptchaTerms = (props: FooterProps) => {
    const privacyPolicyHref = `<a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">${props.policyText}</a>`;
    const termsOfServiceHref = `<a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">${props.termsOfServiceText}</a>`;

    let html = props.googleReCaptchaBranding.replace('{0}', String(privacyPolicyHref));
    html = html.replace('{1}', String(termsOfServiceHref));

    return html;
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        productName: commonSelectors.commonData(state).ProductName,
        policyText: resource("common-privacypolicytext"),
        policyUrl: commonSelectors.commonData(state).PrivacyPolicyUrl,
        termsOfServiceText: resource("common-termsofservicetitle"),
        googleReCaptchaBranding: resource("common-googlerecaptchabranding"),
        ...ownProps
    }
};

export default connect(mapStateToProps)(Footer);