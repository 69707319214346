import React from 'react';
import { ConditionalShow } from "./ConditionalShow";
import { InputProps } from "@progress/kendo-react-inputs";
import { FieldProps, Field } from "formik";
import { Checkbox } from '@progress/kendo-react-inputs';

export interface IInputFieldProps {
    label: string;
    inputProps?: InputProps;
    fieldName: string;
    hidden?: boolean;
    placeholder?: string;
    isRequired?: boolean;
    info?: string;
}

export function CheckboxField(props: IInputFieldProps) {
    return (
        <ConditionalShow {...props}>
            <Field
                name={props.fieldName}
                id={props.fieldName}>
                {(fieldProps: FieldProps<any>) =>
                    <div className="form__checkbox-container k-relative k-d-inline-flex">
                        <Checkbox
                            rounded="small"
                            id={props.fieldName}
                            onChange={(e) => fieldProps.form.setFieldValue(props.fieldName, e.value)}
                            label={props.label}
                            checked={fieldProps.field.value}
                            className="form__checkbox k-d-flex k-align-items-start" />
                    </div>
                }
            </Field>
        </ConditionalShow>
    );
}