import React from 'react';
import { connect } from 'react-redux';
import { DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { orderBy as _orderBy } from "lodash";
import { Language } from '../model/commonModel';
import { Ripple } from '@progress/kendo-react-ripple';
import { ApplicationState } from '../../store/configureStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

export interface LanguageBarProps {
    selectedLanguage: Language;
    languages: Array<Language>;
    reloadFormDataAction: (e: DropDownButtonItemClickEvent) => void;
}

class LanguageBar extends React.Component<LanguageBarProps> {  

    render() {
        const orderedLanguages = _orderBy(this.props.languages, (language: Language) => language.NativeName.toLowerCase());

        function findLanguageByNativeName(array: any, text: any) {
            return array.find((element: { NativeName: any; }) => {
                return element.NativeName === text;
            })
        }

        //set items icon, text and kendo states "selected" "disabled" for current language
        const languageItems = orderedLanguages.map((language) => {
            return {
                icon: this.props.selectedLanguage.Code === language.Code
                    ? "check"
                    : " ",
                text: language.NativeName,
                selected: this.props.selectedLanguage.Code === language.Code,
                disabled: this.props.selectedLanguage.Code === language.Code
            }
        });

        return (
            <div className="languagebar k-d-flex k-flex-nowrap k-align-items-center">
                <FontAwesomeIcon icon={faGlobe} size="lg" />
                <DropDownButton
                    className="languagebar-menu"
                    text={this.props.selectedLanguage.TwoLetterISOName}
                    items={languageItems}
                    onItemClick={
                        (e: DropDownButtonItemClickEvent) =>
                            this.props.reloadFormDataAction(findLanguageByNativeName(orderedLanguages, e.item.text))
                    }
                    fillMode="flat"
                    popupSettings={{
                        anchorAlign: {
                            horizontal: "right",
                            vertical: "bottom"
                        },
                        popupAlign: {
                            horizontal: "right",
                            vertical: "top"
                        }
                    }}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        selectedLanguage: state.common.selectedLanguage,
        languages: state.common.languages,
        ...ownProps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageBar);