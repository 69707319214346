export interface AdditionalFieldViewModel {
    id: number;
    name: string;
    type: AdditionalFieldTypeItem;
    isEnabled: boolean;
    modifiedAt: string;

    textValue?: string;
    options: AdditionalFieldOptionViewModel[];
    optionValue?: { id: number; name: string; }[];
    categoryId: number | null;
}

export enum AdditionalFieldType {
    Text = 0,
    MultiSelectOptions = 1
}

export interface AdditionalFieldTypeItem {
    id: AdditionalFieldType;
    name: string;
}

export interface AdditionalFieldOptionViewModel {
    id: number;
    name: string;
}