import React from 'react';
import FullscreenMessage from './FullscreenMessage';

export interface LoadingProps {
}

const Loading: React.SFC<LoadingProps> = (props) => (
    <FullscreenMessage showSpinner />
);

export default Loading;