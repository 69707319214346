import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import LanguageBar from './LanguageBar';
import { connect } from 'react-redux';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';

export interface TitleBarProps {
    objectName: string;
    title: string;
    reloadFormDataAction: (e: DropDownButtonItemClickEvent) => void;
}

const TitleBar: React.SFC<TitleBarProps> = (props) => (
    <div className="titlebar theme-bg theme-color k-sticky">
        
        <div className="titlebar__title-container k-d-flex k-align-items-center">
            <label className="titlebar__title">
                {props.title}
            </label>
            <LanguageBar reloadFormDataAction={props.reloadFormDataAction} />
        </div>
        <div className="titlebar__object-name-container">
            <FontAwesomeIcon icon={faMapMarkerAlt} size='sm' />
            <span className="titlebar__object-name k-d-inline-flex">
                {props.objectName}
            </span>
        </div>
    </div>
);

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        ...ownProps
    }
};

export default connect(mapStateToProps)(TitleBar);