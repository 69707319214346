import React, { useEffect, useState } from 'react';
import internalizationLoader from '../internalizationLoader';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import Loading from './Loading';
import { initLocalization } from '../localizationsService';

interface ILocalizationProps {
    kendoCode: string;
    children: any;
}

export function Localization(props: ILocalizationProps) {
    let [isRendered, setRendered] = useState(false);

    useEffect(() => {
        if (!props.kendoCode) { // skip when value is not fetched yet
            return;
        }

        setRendered(false);

        internalizationLoader(props.kendoCode).then(() => {
            initLocalization(props.kendoCode).then(() => {
                setRendered(true);
            })
        }).catch((e) => {
            console.log("localization failed (infinite spinner)");
            setRendered(false);
        });
    }, [props.kendoCode]);

    return (
        <>
            {
                !isRendered
                ?
                <Loading />
                :
                <IntlProvider locale={props.kendoCode}>
                    <LocalizationProvider language={props.kendoCode}>
                        <>
                            {props.children}
                        </>
                    </LocalizationProvider>
                </IntlProvider>
            }
        </>
    );
}