import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../common/components/Footer"
import Loading from "../../common/components/Loading";
import { loadFeedbackFormDataAsyncAction } from "../sagas/feedbackFormSaga";
import FeedbackForm from "./FeedbackForm";

export default function FeedbackFormWrapper()
{
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadFeedbackFormDataAsyncAction());
    }, []);

    const isFetching = useSelector((state: any) => state.common.isFetching);
    const isSubmitting = useSelector((state: any) => state.common.isSubmitting);

    return (
        <>
            {
                isFetching || isSubmitting ?
                <Loading />
                :
                <>
                    <FeedbackForm />
                    <Footer hideRecaptha={true} />
                </>
            }
        </>
    );
}