import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedbackFormData } from '../model/feedbackModel';

export interface FeedbackFormState {
    feedbackFormData: FeedbackFormData | null;
}

export const initialState: FeedbackFormState = {
    feedbackFormData: null
};

export const feedbackFormSlice = createSlice({
    name: 'feedbackForm',
    initialState: initialState,
    reducers: {
        setFeedbackFormData: (state, action: PayloadAction<FeedbackFormData>) => {
            state.feedbackFormData = action.payload;
        }
    }
})

export const feedbackFormReducer = feedbackFormSlice.reducer;
export const feedbackFormActions = feedbackFormSlice.actions;