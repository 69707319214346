import React from 'react';

export function ConditionalShow(props: any) {
    return (
        <>
            {
                !props.hidden
                    ? props.children
                    : null
            }
        </>
    );
}