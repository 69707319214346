import React, { RefObject } from 'react';
import { connect } from 'react-redux';
import { Upload, UploadHttpHeaders, UploadOnRemoveEvent, UploadFileInfo, UploadOnProgressEvent, UploadOnStatusChangeEvent, UploadOnAddEvent, UploadFileStatus, UploadOnBeforeRemoveEvent, UploadOnBeforeUploadEvent } from '@progress/kendo-react-upload';
import { LocalizationProvider } from '@progress/kendo-react-intl';
import { Field, FieldProps } from 'formik';
import FilePreview from './FilePreview';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { forEach as _forEach, filter as _filter, concat as _concat, every as _every, flatten as _flatten, some as _some, uniq as _uniq } from "lodash";
import { IFilePreview, Language, IntlMessages } from '../../common/model/commonModel';
import * as commonSelectors from '../../common/selectors/commonSelectors';
import _ from 'lodash';

export interface UploadDocumentsProps {
    selectedLanguage: Language;
    requestFormGuid: string;
    setFieldValue: (fieldName: string, fieldValue: any) => void;
    fieldName: string;
    maxFileCount?: number;
    maxFileSize?: number;
    localizedMessages: IntlMessages;
    imageTypeList: string[];
    fileTypeList: string[];
}

interface UploadDocumentsState {
    filePreviews: IFilePreview[];
    errorTooManyFiles: string;
    errorInvalidFiles: string;
    showNotification: boolean;
    errors: string[];
}

class UploadDocuments extends React.Component<UploadDocumentsProps, UploadDocumentsState> {
    private DEFAULT_MAX_FILE_COUNT = 4;
    private DEFAULT_MAX_FILE_SIZE = 10;

    constructor(props: UploadDocumentsProps) {
        super(props);

        this.state = {
            filePreviews: [],
            errorTooManyFiles: '',
            errorInvalidFiles: '',
            showNotification: false,
            errors: []
        }
    }

    uploadComponent: RefObject<Upload> = React.createRef();

    onChange = async (event: UploadOnProgressEvent | UploadOnStatusChangeEvent | any): Promise<void> => {
        if (event.response != null && event.response.response != null && event.response.response.isValid === false) {
            _.each(event.affectedFiles, file => {
                this.uploadComponent.current!.onCancel(file.uid);
            });

            this.setState({ errorInvalidFiles: 'invalidFiles' }, () => this.updateErrors(event.affectedFiles));

            return;
        }

        this.props.setFieldValue(this.props.fieldName, event.newState);

        // update file status for previews
        let newList = this.state.filePreviews;

        _forEach(event.affectedFiles, item => {
            var previewFile = newList.find(file => file.uid === item.uid);
            if (previewFile != null) {
                previewFile.isUploading = item.status === UploadFileStatus.Uploading;
            }
        });

        return new Promise((resolve) => {
            this.setState({ filePreviews: newList }, () => { resolve(); });
        });
    };

    onAdd = async (event: UploadOnAddEvent, files: UploadFileInfo[]) => {
        const afterStateChange = async (validFiles: UploadFileInfo[]) => {
            let newList = this.state.filePreviews;

            let imageTypes = this.props.imageTypeList;

            validFiles
                .forEach((file: UploadFileInfo) => {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        let newFile: IFilePreview = {
                            uid: file.uid,
                            name: file.name,
                            status: file.status,
                            progress: file.progress,
                            extension: file.extension,
                            rawFile: reader.result as string,
                            isImage: imageTypes.some((item: string) => (file.extension || "").toLowerCase() === item.toLowerCase()),
                            validationErrors: file.validationErrors,
                            isUploading: file.status === UploadFileStatus.Uploading || file.status === UploadFileStatus.Selected
                        };

                        newList.push(newFile);
                    };

                    if (file.getRawFile !== undefined) {
                        reader.readAsDataURL(file.getRawFile());
                    }
                });

            return new Promise<void>((resolve) => this.setState({ filePreviews: newList }, () => { resolve(); }));
        };

        const maxFileCount = this.props.maxFileCount || this.DEFAULT_MAX_FILE_COUNT;

        const validFiles = event.affectedFiles.filter((file: UploadFileInfo) => (file.status !== UploadFileStatus.UploadFailed && file.validationErrors == null));
        const invalidFiles = event.affectedFiles.filter((file: UploadFileInfo) => (file.status === UploadFileStatus.UploadFailed || file.validationErrors != null));
        const newFileCount = files.length + event.affectedFiles.length;

        if (newFileCount > maxFileCount) {
            // error too may
            this.setState({ errorTooManyFiles: "maximumFileCount" }, () => this.updateErrors(event.affectedFiles));
        }
        else if (validFiles.length > 0) {
            event.affectedFiles = validFiles;
            // remove invalid files from newState
            event.newState = _filter(event.newState, (item) => _every(invalidFiles, (file) => file.uid !== item.uid));
            await this.onChange(event);
            await afterStateChange(validFiles);
            this.updateErrors(_concat(invalidFiles, validFiles));
        } else {
            this.updateErrors(event.affectedFiles);
        }
    }

    onRemove = async (event: UploadOnRemoveEvent) => {
        let newList = this.state.filePreviews;

        event.affectedFiles.forEach((file: UploadFileInfo) => {
            newList = newList.filter((item: IFilePreview) => item.uid !== file.uid);
        });

        this.setState({ filePreviews: newList, errorTooManyFiles: '' }, async () => {
            await this.onChange(event);
            this.updateErrors(event.affectedFiles);
        });
    }

    updateErrors = (affectedFiles: UploadFileInfo[]) => {
        const errors = this.getErrors(affectedFiles);
        const showNotification = _some(errors);

        this.setState({ errors: errors, showNotification: showNotification });
    }

    getErrors = (affectedFiles: UploadFileInfo[]): string[] => {
        const fileErrors = _flatten(affectedFiles.map((item: UploadFileInfo) => {
            return item.validationErrors || [];
        })).filter(item => item); // remove undefined and null items

        let uniqueErrors: string[] = [];

        // if we are removing file, don't show error
        let removing = affectedFiles.length > 0 && affectedFiles[0].status === UploadFileStatus.Removing;

        if (this.state.errorTooManyFiles.length > 0 && !removing) {
            uniqueErrors.push(this.state.errorTooManyFiles);
        }

        if (this.state.errorInvalidFiles.length > 0) {
            uniqueErrors.push(this.state.errorInvalidFiles);
        }

        uniqueErrors = _concat(uniqueErrors, _uniq(fileErrors));

        return uniqueErrors.map((item) => this.getLocalizedError(item));
    }
    
    onRemoveFile = (file: IFilePreview) => {
        this.uploadComponent.current!.onRemove(file.uid);
    }

    getLocalizedError = (errorKey: string) : string => {
        const maxFileCount = this.props.maxFileCount || this.DEFAULT_MAX_FILE_COUNT;

        switch (errorKey) {
            case "invalidFileExtension":
                return this.props.localizedMessages.messages.upload.invalidFileExtension;
            case "invalidFiles":
                return this.props.localizedMessages.messages.upload.invalidFiles;
            case "invalidMaxFileSize":
                return this.props.localizedMessages.messages.upload.invalidMaxFileSize.replace('{0}', String(this.getMaxFileSize()));
            case "invalidMinFileSize":
                return this.props.localizedMessages.messages.upload.invalidMinFileSize;
            case "maximumFileCount":
                return this.props.localizedMessages.messages.upload.maximumFileCountText.replace('{0}', String(maxFileCount));
        }

        return '';
    }

    getMaxFileSize = () => {
        return this.props.maxFileSize || this.DEFAULT_MAX_FILE_SIZE;
    }

    render() {
        var uploadHeader: UploadHttpHeaders = {
            "X-RequestFormGuid": this.props.requestFormGuid
        };

        const allowedExtensions: string[] = this.props.fileTypeList
            .concat(this.props.imageTypeList);

        // file-add-area title must be set empty, otherwise browsers default tooltip for input field is visible 'No file chosen'
        return (
            <>
                <NotificationGroup className="notification">
                    {
                        this.state.showNotification &&
                        <Notification
                            type={{
                                style: 'error',
                                icon: true
                            }}
                            closable={true}
                            onClose={() => { 
                                this.setState({
                                    showNotification: false,
                                    errorTooManyFiles: '',
                                    errorInvalidFiles: ''
                                })
                            }}>
                            <span>
                                {this.state.errors.map((error, index) => <div key={index}>{error}</div>)}
                            </span>
                        </Notification>
                    }
                </NotificationGroup>
                <Field name={this.props.fieldName}>
                    {(fieldProps: FieldProps<any>) =>
                        <div className="file-add-area k-relative" title="">
                            <LocalizationProvider language={this.props.selectedLanguage.Code}>
                                    <Upload
                                        className="original-upload"
                                        ref={this.uploadComponent}
                                        saveHeaders={uploadHeader}
                                        removeHeaders={uploadHeader}
                                        multiple={true}
                                        files={fieldProps.field.value}
                                        onAdd={(event: UploadOnAddEvent) => this.onAdd(event, fieldProps.field.value)}
                                        onRemove={this.onRemove}
                                        onProgress={this.onChange}
                                        onStatusChange={this.onChange}
                                        saveUrl={'/api/Upload/Save'}
                                        removeUrl={'/api/Upload/Remove'}
                                        showFileList={false}
                                        onBeforeUpload={(event: UploadOnBeforeUploadEvent) => {
                                            event.additionalData = { uid: event.files[0].uid };
                                        }}
                                        onBeforeRemove={(event: UploadOnBeforeRemoveEvent) => {
                                            event.additionalData = { uid: event.files[0].uid };
                                        }}
                                        restrictions={{
                                            maxFileSize: this.getMaxFileSize() * 1000000,
                                            minFileSize: 1, // cant be empty file
                                            allowedExtensions: allowedExtensions
                                        }}
                                    />
                            </LocalizationProvider>
                            {
                                fieldProps.field.value.length
                                    ? <FilePreview
                                        filePreviews={this.state.filePreviews}
                                        onRemove={this.onRemoveFile} />
                                    : null
                            }
                        </div>
                    }
                </Field>
            </>
        );
    }
}
        
const mapStateToProps = (state: any, ownProps: any) => {
    return {
        selectedLanguage: commonSelectors.selectedLanguage(state),
        localizedMessages: commonSelectors.localizedMessages(state),
        ...ownProps
    }
};

export default connect(mapStateToProps)(UploadDocuments);
