import { load } from 'recaptcha-v3';
import { RaygunStatic } from 'raygun4js';
import { ClientSettings, ClientUrlParams, Language } from '../model/commonModel';
import { call, put, select } from 'redux-saga/effects';
import commonService from '../service/commonService';
import { push } from 'connected-react-router';
import * as commonSelectors from '../selectors/commonSelectors';
import { takeEvery } from "redux-saga/effects";
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { commonActions } from '../reducers/commonReducers';

declare var Raygun: RaygunStatic;

export const initAsyncAction = createAction<'servicerequest' | 'feedback'>('COMMON_INIT_FETCH');

export const commonSagas = {
    watchInitAsyncAction: takeEvery(initAsyncAction, initSaga),
};

export function* initSaga({ payload: mode }: PayloadAction<string>) {
    const isInitialFetching: boolean = yield select(commonSelectors.isInitialFetching);

    if (!isInitialFetching) {
        return;
    }

    const clientUrlParams: ClientUrlParams = yield call(commonService.getUrlParams, mode);
    if (!clientUrlParams) {
        yield put(push('/error'));
        return;
    }

    yield put(commonActions.setServiceRequestModuleInUse(clientUrlParams.isServiceRequestModuleInUse));

    const languages: Language[] = yield call(commonService.getLanguages);
    yield put(commonActions.setLanguageCodes(languages));

    let selectedLanguage = languages.find((item: Language) => item.Code === clientUrlParams.languageCode);
    if (selectedLanguage) {
        selectedLanguage = fillShortLanguage(selectedLanguage);
        yield put(commonActions.selectLanguage(selectedLanguage));
    }

    const clientSettings: ClientSettings = yield call(commonService.getClientSettings);
    yield put(commonActions.setClientSettings(clientSettings));

    yield put(commonActions.setInitialFetching(false));
}

export function initLogging(apiKey: string, formGuid?: string): void {
    if (apiKey || apiKey.length > 0) {
        // init logging
        Raygun.init(apiKey, { disablePulse: true, ignoreAjaxAbort: true });
        Raygun.attach();
        Raygun.withCustomData({
            FormGuid: formGuid,
            Sender: "ServiceRequestUI"
        });
    }
}

export function fillShortLanguage(language: Language): Language {
    language = JSON.parse(JSON.stringify(language)); // clone so it can be mutated

    const availableKendoLanguages = [
        "fi",
        "de",
        "da",
        "ru",
        "sv",
        "et",
        "lt",
        "lv",
        "zh",
        "nb",
        "en-GB",
        "sv-FI",
        "ar-AE"
    ];
    
    // try with longer code "en-gb" first
    let matchedLanguage = availableKendoLanguages.find((availableLanguage: string) => availableLanguage === language.Code);
    
    // HACK: legacy api support, remove after 1 version
    if (language.TwoLetterISOName === undefined) {
        language.TwoLetterISOName = language.Code.substring(0, 2);
    }

    // then with two letter if not found
    if (matchedLanguage == null) {
        matchedLanguage = availableKendoLanguages.find((availableLanguage: string) => availableLanguage === language.TwoLetterISOName);
    }

    if (matchedLanguage == null) {
        throw new Error(`fillShortLanguage: unsupported language ${language.Code} (${language.TwoLetterISOName}) failed to load`);
    }
    
    language.KendoCode = matchedLanguage;

    return language;
}

export async function generateRecaptchaToken(recaptchaSiteKey: string) {
    try {
        const recaptcha = await load(recaptchaSiteKey, { autoHideBadge: true, useRecaptchaNet: true });
        const token = await recaptcha.execute('homepage');
        return token;
    }
    catch (e) {
        throw new Error(`generateRecaptchaToken failed: Did you remember to add the current site ip/domain to recaptcha allowed domains list? Base error=${e}, Stack=${e.stack}`);
    }
}