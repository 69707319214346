import { put, call, select } from 'redux-saga/effects';
import { loadMessages } from '@progress/kendo-react-intl';
import * as commonSelectors from '../../common/selectors/commonSelectors';
import { Language, ClientSettings, ClientResponse } from '../../common/model/commonModel';
import { v4 as uuidv4 } from 'uuid';
import { fillShortLanguage, generateRecaptchaToken, initLogging } from '../../common/sagas/commonSaga';
import { getModuleServiceRequestFormData, saveModuleServiceRequest } from '../service/moduleRequestService';
import { createCommonData, moduleRequestFormActions } from '../reducers/moduleRequestFormReducers';
import { ModuleRequestFormData } from "../model/ModuleRequestFormData";
import { push } from 'connected-react-router';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { takeEvery } from "redux-saga/effects";
import { commonActions } from '../../common/reducers/commonReducers';
import commonService from '../../common/service/commonService';

export const loadModuleRequestFormDataAsyncAction = createAction<Language | undefined>('MODULE_REQUEST_FORM_DATA_FETCH');
export const moduleRequestFormSubmitAsyncAction = createAction<ModuleRequestFormData>('MODULE_REQUEST_FORM_SUBMIT');
export const moduleRequestFormReCaptchaV3VerificationAsyncAction = createAction('MODULE_REQUEST_FORM_RECAPTCHA_V3_VERIFICATION');
export const setModuleRequestFormReCaptchaV2TokenAsyncAction = createAction<string | null>('MODULE_REQUEST_FORM_RECAPTCHA_V2_SET');

export const moduleRequestFormSagas = {
    watchLoadModuleRequestFormDataAsyncAction: takeEvery(loadModuleRequestFormDataAsyncAction, loadModuleRequestFormDataSaga),
    watchModuleRequestFormSubmitAsyncAction: takeEvery(moduleRequestFormSubmitAsyncAction, moduleRequestFormSubmitSaga),
    watchModuleRequestFormReCaptchaV3VerificationAsyncAction: takeEvery(moduleRequestFormReCaptchaV3VerificationAsyncAction, moduleRequestFormReCaptchaV3VerificationSaga),
    watchSetModuleRequestFormReCaptchaV2TokenSagaAsyncAction: takeEvery(setModuleRequestFormReCaptchaV2TokenAsyncAction, setModuleRequestFormReCaptchaV2TokenSaga)
};

export function* loadModuleRequestFormDataSaga({ payload: selectedLanguage }: PayloadAction<Language | undefined>) {
    yield put(commonActions.fetchStart());

    // guid is used by temporary file storage
    const formGuid: string = uuidv4();
    yield put(moduleRequestFormActions.setModuleRequestFormGuid(formGuid));
    const clientSettings: ClientSettings = yield select(commonSelectors.clientSettings);
    yield call(initLogging, clientSettings.logApiKey, formGuid);

    // update language if given
    if (selectedLanguage) {
        selectedLanguage = fillShortLanguage(selectedLanguage);
        yield put(commonActions.selectLanguage(selectedLanguage));
    } else {
        selectedLanguage = yield select(commonSelectors.selectedLanguage);
    }

    const requestFormDataWithCommonData: ModuleRequestFormData = yield call(getModuleServiceRequestFormData, selectedLanguage!.Code, formGuid);

    const loc = {
        messages: {
            "upload": requestFormDataWithCommonData.fileUploadTexts
        }
    };

    yield put(commonActions.setLocalizedMessages(loc));

    yield call(loadMessages, loc.messages, selectedLanguage!.Code);

    yield put(moduleRequestFormActions.setModuleRequestFormData(requestFormDataWithCommonData));
    yield put(commonActions.setCommonData(createCommonData(requestFormDataWithCommonData)));

    yield put(commonActions.fetchEnd());
}

export function* moduleRequestFormSubmitSaga({ payload: formData }: PayloadAction<ModuleRequestFormData>) {
    yield put(commonActions.fetchStart());

    const selectedLanguage: Language = yield select(commonSelectors.selectedLanguage);

    const saveResponse: ClientResponse = yield call(saveModuleServiceRequest, formData, formData.captchaToken, selectedLanguage.Code);
    yield put(commonActions.resetCaptchaTokens());

    if (!saveResponse.isValid) {
        yield put(push('/error'));
        return;
    }

    const location: Location = yield select(commonSelectors.location);

    yield put(push({
        pathname: '/requestSent',
        search: location.search // preserve query parameters
    } as any));

    yield put(commonActions.fetchEnd());
}

export function* moduleRequestFormReCaptchaV3VerificationSaga() {
    const clientSettings: ClientSettings = yield select(commonSelectors.clientSettings);
    let token: string = yield call(generateRecaptchaToken, clientSettings.reCaptchaV3SiteKey);
    
    let isValidToken: boolean = yield call(commonService.validateReCaptcha, token);
    yield put(commonActions.setCaptchaV3Token({Token: token, Valid: isValidToken, ShowReCaptchaV2: !isValidToken}));
}

export function* setModuleRequestFormReCaptchaV2TokenSaga({ payload: token }: PayloadAction<string | null>) {
    yield put(commonActions.setCaptchaV2Token({Token: token}));
}