import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonData } from '../../common/model/commonModel';
import { ModuleRequestFormData } from '../model/ModuleRequestFormData';

export function createCommonData(data: any): CommonData {
    return {
        PrivacyPolicyUrl: data.privacyPolicyUrl,
        ProductName: data.productName,
    }
}

export interface ModuleRequestFormState {
    moduleRequestFormData: ModuleRequestFormData | null;
    moduleRequestFormGuid: string;
}

export const initialState: ModuleRequestFormState = {
    moduleRequestFormData: null,
    moduleRequestFormGuid: ''
};

export const moduleRequestFormSlice = createSlice({
    name: 'moduleRequestForm',
    initialState: initialState,
    reducers: {
        setModuleRequestFormData: (state, action: PayloadAction<ModuleRequestFormData>) => {
            state.moduleRequestFormData = action.payload;
        },
        setModuleRequestFormGuid: (state, action: PayloadAction<string>) => {
            state.moduleRequestFormGuid = action.payload;
        }
    }
})

export const moduleRequestFormReducer = moduleRequestFormSlice.reducer;
export const moduleRequestFormActions = moduleRequestFormSlice.actions;