import axios from 'axios';
import { FeedbackFormData, FeedbackQuestionSave,  } from '../model/feedbackModel';
import { ClientResponse } from '../../common/model/commonModel';
import commonService from '../../common/service/commonService';

class FeedbackService {
    save = async (values: any): Promise<ClientResponse> => {
        let freeText = "";
        let answers: FeedbackQuestionSave[] = [];

        Object.keys(values).forEach((key) => {
            if (isNaN(+key)) {
                freeText = values[key];
            } else {
                answers.push({ QuestionId: parseInt(key), QuestionAnswerId: values[key] });
            }
        });

        const feedbackMessage = {
            FeedbackData: {
                // ServiceRequestId is filled from session in backend
                QuestionsAndAnswers: answers,
                FreeText: freeText
            }
            // CustomerGuid is filled from session in backend
        };

        return (await axios.post(`/api/Feedback/Save${commonService.getId()}`, feedbackMessage, commonService.getOptions())).data;
    }

    getFeedbackFormData = async (languageCode: string): Promise<FeedbackFormData> => {
        let options = commonService.getOptions();
        options.headers["Accept-Language"] = languageCode;

        var data: FeedbackFormData = (await axios.get(`/api/Feedback/GetFeedbackFormData${commonService.getId()}`, options)).data;
        if (data.ServiceRequestFinishedDate != null && (data.ServiceRequestFinishedDate as unknown) !== "") {
            data.ServiceRequestFinishedDate = new Date(data.ServiceRequestFinishedDate); // convert date string to date
        }

        return data;
    }
}

export default new FeedbackService();