import { FieldProps, getIn } from 'formik';
import { classNames } from '@progress/kendo-react-common';
import { Hint } from '@progress/kendo-react-labels';

export interface IFieldInfoTextProps {
    valid: boolean;
    fieldProps: FieldProps<any>;
    info?: string;
}

export function FieldInfoText(props: IFieldInfoTextProps) {

    const classes = classNames({
        'k-form-info': props.valid,
        'k-form-error': !props.valid
    });

    const fieldName = props.fieldProps.field.name;
    const hintId = `${fieldName}_hint`;
    const hintMessage = props.valid
        ? props.info ?? ''
        : getIn(props.fieldProps.form.errors, fieldName) ?? '';

    return (
        <>
            {
                hintMessage
                    ? <Hint id={hintId} className={classes}>{hintMessage}</Hint>
                    : null
            }
        </>
    );
}