import { ApplicationState } from "../../store/configureStore";
import { CaptchaV3Result, CommonData } from "../model/commonModel";

export const commonData = (state: ApplicationState) => state.common.commonData as CommonData;
export const clientSettings = (state: ApplicationState) => state.common.clientSettings;
export const selectedLanguage = (state: ApplicationState) => state.common.selectedLanguage;
export const localizedMessages = (state: ApplicationState) => state.common.localizedMessages;
export const isInitialFetching = (state: ApplicationState): boolean => state.common.isInitialFetching;
export const location = (state: ApplicationState) => state.router.location;
export const reCaptchaV3Result = (state: ApplicationState) => state.common.captchaV3Result;
export const reCaptchaV2Token = (state: ApplicationState) => state.common.captchaV2Token;