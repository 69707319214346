import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../common/components/Footer"
import FullscreenMessage from "../../common/components/FullscreenMessage"
import RequestForm from "./RequestForm"
import * as requestFormSelectors from '../../servicerequest/selectors/requestFormSelectors';
import Loading from "../../common/components/Loading";
import { loadRequestFormDataAsyncAction } from "../sagas/requestFormSaga";
import { ApplicationState } from "../../store/configureStore";

export default function RequestFormWrapper()
{
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRequestFormDataAsyncAction());
    }, []);

    const isIncompleteDefinitions = useSelector((state: ApplicationState) => requestFormSelectors.requestFormData(state))?.IsIncompleteDefinitions ?? true;
    const isFetching = useSelector((state: any) => state.common.isFetching);
    const isSubmitting = useSelector((state: any) => state.common.isSubmitting);

    return (
        <>
            {
                isFetching || isSubmitting ?
                <Loading />
                :
                isIncompleteDefinitions ?
                    <FullscreenMessage
                        iconName="exclamation-circle"
                        resourceKey={"ppp-portlet-incomplete-definitions"} />
                    :
                    <>
                        <RequestForm />
                        <Footer />
                    </>
            }
        </>
    );
}