import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Ripple } from '@progress/kendo-react-ripple';
import { Formik, FormikProps, FormikHelpers, Form, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from 'yup';
import UploadDocuments from '../../common/components/UploadDocuments';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { RequestFormData } from '../model/requestFormModel';
import * as requestFormSelectors from '../selectors/requestFormSelectors';
import { CaptchaV2Token, CaptchaV3Result, ClientSettings, Language } from '../../common/model/commonModel';
import TitleBar from '../../common/components/TitleBar';
import TextAreaField from '../../common/components/Field/TextAreaField';
import { DropDownListField } from '../../common/components/Field/DropDownListField';
import TextBoxField from '../../common/components/Field/TextBoxField';
import { CheckboxField } from '../../common/components/Field/CheckboxField';
import CustomCookieConsent from '../../common/components/CustomCookieConsent';
import { resource } from '../../common/utils';
import { loadRequestFormDataAsyncAction, requestFormReCaptchaV3VerificationAsyncAction, requestFormSubmitAsyncAction, setRequestFormReCaptchaV2TokenAsyncAction } from '../sagas/requestFormSaga';
import { ApplicationState } from '../../store/configureStore';
import ReCAPTCHA from "react-google-recaptcha";
import * as commonSelectors from '../../common/selectors/commonSelectors';

export interface RequestFormProps {
    requestFormGuid: string;
    requestFormData: RequestFormData;
    clientSettings: ClientSettings;
    reCaptchaV3Result: CaptchaV3Result | null,
    reCaptchaV2Token: CaptchaV2Token | null,
    isMandatoryText: string;
    fieldMaxLengthText: string;
    fieldContainsIllegalCharacters: string;
    requestFormSubmit: (values: any) => void;
    loadRequestFormData: (language: Language) => void;
    reCaptchaV3Verification: () => void;
    setReCaptchaV2Token: (token: string | null) => void;
}

export const validateInvalidCharacters = (value: string | undefined) => {
    if (!value) return true;

    const illegalCharacters: RegExp = /[<>/%]+/g; // regexp needs to be recreated always for the handling to go correctly (calling will advance the lastIndex)
    const hasIllegalChars = illegalCharacters.test(value);
    return !hasIllegalChars;
}

// copied from manager GMValidator.ts isValidEmail() 11.6.2019
export const emailRegExp: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneNumberRegExp: RegExp = /^[\d+][ \d-]+[\d]+$/;

const addNewServiceSchema = (props: RequestFormProps) => 
    Yup.object().shape({
        description: Yup.string()
            .test('has-invalid-characters', props.fieldContainsIllegalCharacters, validateInvalidCharacters)
            .trim()
            .required(props.isMandatoryText)
            .max(5000, props.fieldMaxLengthText),
        name: Yup.string()
            .test('has-invalid-characters', props.fieldContainsIllegalCharacters, validateInvalidCharacters)
            .trim()
            .required(props.isMandatoryText)
            .max(256, props.fieldMaxLengthText),
        email: Yup.string()
            .matches(emailRegExp, { excludeEmptyString: true, message: resource("common-emailinvalid") }) // use this instead of .email() because we dont want to support ��� etc
            .required(props.isMandatoryText)
            .max(256, props.fieldMaxLengthText),
        phone: Yup.string()
            .matches(phoneNumberRegExp, { excludeEmptyString: true, message: resource("common-phonenumberinvalid") })
            .max(30, props.fieldMaxLengthText)
            .required(props.isMandatoryText),
        additionalInfo: Yup.string()
            .test('has-invalid-characters', props.fieldContainsIllegalCharacters, validateInvalidCharacters)
            .max(255, props.fieldMaxLengthText),
        selectedServiceArea: Yup.object()
            .nullable()
            .when('$data', (data: RequestFormData, schema: Yup.AnySchema<any>) => data.ServiceAreaRequired ? schema.required(props.isMandatoryText) : schema),
        selectedAdviceType: Yup.object()
            .nullable()
            .when('$data', (data: RequestFormData, schema: Yup.AnySchema<any>) => data.AdviceTypeRequired ? schema.required(props.isMandatoryText) : schema),
        selectedCostCenter: Yup.object()
            .nullable()
            .when('$data', (data: RequestFormData, schema: Yup.AnySchema<any>) => data.CostCenterRequired ? schema.required(props.isMandatoryText) : schema)
    });

class RequestForm extends React.Component<RequestFormProps> {
    constructor(props: RequestFormProps) {
        super(props);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.getValidRecaptchaTokenWithVersionInfo = this.getValidRecaptchaTokenWithVersionInfo.bind(this);
    }

    private handleFormSubmit(submit: () => Promise<void>) {
        if (this.props.clientSettings.disableCaptcha || this.getValidRecaptchaTokenWithVersionInfo()) {
            // Token exists or captcha is disabled, submit form
            submit();
        }
        else {
            // Get token first and submit if result is valid
            this.props.reCaptchaV3Verification();
        }
    }

    private getValidRecaptchaTokenWithVersionInfo() {
        if (this.props.reCaptchaV3Result?.Valid && this.props.reCaptchaV3Result.Token) {
            return this.props.reCaptchaV3Result.Token + "&v3";
        }
        else if (this.props.reCaptchaV2Token?.Token) {
            return this.props.reCaptchaV2Token.Token + "&v2";
        }

        return null;
    }

    render() {
        let data = this.props.requestFormData;

        return (
            <Formik
                enableReinitialize={false}
                initialValues={{
                    selectedServiceArea: data.ServiceAreas.length === 1 ? data.ServiceAreas[0] : null, // if only one option default to it and dont show it
                    selectedAdviceType: data.AdviceTypes.length === 1 ? data.AdviceTypes[0] : null,
                    selectedCostCenter: null,
                    description: "",
                    additionalInfo: "",
                    name: "",
                    phone: "",
                    email: "",
                    notifyCreatorOnStateChange: true,
                    files: []
                }}
                validate={(values: any) => {
                    try {
                        validateYupSchema<any>(values, addNewServiceSchema(this.props), true, { data });
                    } catch (err) {
                        return yupToFormErrors(err);
                    }

                    return {};
                }}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>) => {
                    let token = this.getValidRecaptchaTokenWithVersionInfo();
                    this.props.requestFormSubmit({
                        requestFormGuid: this.props.requestFormGuid,
                        CaptchaToken: token,
                        ...values
                    });
                }}
            >
                {(formikProps: FormikProps<any>) => {
                    // file upload status must be Uploaded
                    let uploadReady = formikProps.values["files"].every((file: UploadFileInfo) => (file.status === 4));

                    let showRecaptchaV2 = this.props.reCaptchaV3Result?.ShowReCaptchaV2 && !formikProps.isSubmitting;
                    let recaptchaV2Token = this.props.reCaptchaV2Token?.Token;

                    let themeColor = getComputedStyle(document.body).getPropertyValue('--themeColor');
                    let themebackground = getComputedStyle(document.body).getPropertyValue('--themeBackground');
                    let subtitleColor = (themeColor == "#ffff") ? "#rgba(0,0,0, .87)" : themebackground;

                    useEffect(() => {
                        if (this.props.reCaptchaV3Result?.Valid == true) {
                            formikProps.submitForm();
                        }
                    }, [this.props.reCaptchaV3Result]);

                    return (
                        <div className="main">
                            <div className="form-container">
                                <TitleBar title={resource("ppp-newservice")} objectName={this.props.requestFormData.CodedObject.DisplayName} reloadFormDataAction={this.props.loadRequestFormData} />
                                <Form className="form">
                                    <Ripple>
                                        <div className="requestArea">
                                            <TextAreaField
                                                autoFocus={true}
                                                fieldName="description"
                                                title={resource("ppp-portlet-column-description")}
                                                label={resource("ppp-portlet-column-description")}
                                                isRequired
                                                placeholder={data.DefaultNewServiceRequestPlaceHolder}
                                                isPlaceholderEnabled={data.UseDefaultNewServiceRequestPlaceHolder} />
                                            <UploadDocuments
                                                requestFormGuid={this.props.requestFormGuid}
                                                imageTypeList={this.props.requestFormData.FileUploadTexts.ImageTypeList}
                                                fileTypeList={this.props.requestFormData.FileUploadTexts.FileTypeList}
                                                fieldName="files"
                                                setFieldValue={formikProps.setFieldValue} />
                                        </div>
                                        <DropDownListField
                                            fieldName="selectedServiceArea"
                                            hidden={!data.ServiceAreaEnabled}
                                            label={resource("common-servicearea")}
                                            isRequired={data.ServiceAreaRequired}
                                            dropdownProps={{
                                                data: data.ServiceAreas,
                                                textField: "Value",
                                                dataItemKey: "Key"
                                            }} />
                                        <DropDownListField
                                            fieldName="selectedAdviceType"
                                            hidden={!data.AdviceTypeEnabled}
                                            label={resource("common-serviceareatype")}
                                            isRequired={data.AdviceTypeRequired}
                                            dropdownProps={{
                                                data: data.AdviceTypes,
                                                textField: "AdviceTypeName",
                                                dataItemKey: "AdviceTypeGuid"
                                            }} />
                                        <TextBoxField
                                            fieldName="additionalInfo"
                                            hidden={!data.IsAdditionalInfoVisible}
                                            label={data.AdditionalInfoHeader}
                                            info={data.AdditionalInfoHelp} />

                                        <div className="request-form-contact-information">
                                            <div
                                                className="request-form-subtitle"
                                                style={{ color: subtitleColor }}
                                            >
                                                {resource("common-contacts")}
                                            </div>
                                            <TextBoxField
                                                fieldName="name"
                                                label={resource("common-name")}
                                                isRequired />
                                            <TextBoxField
                                                fieldName="phone"
                                                label={resource("ppp-labelphonenumberheader")}
                                                isRequired />
                                            <TextBoxField
                                                fieldName="email"
                                                label={resource("common-email")}
                                                isRequired />
                                            <DropDownListField
                                                fieldName="selectedCostCenter"
                                                hidden={!data.CostCenterEnabled}
                                                label={resource("ppp-costcentersheader")}
                                                isRequired={data.CostCenterRequired}
                                                filterable
                                                dropdownProps={{
                                                    data: data.CostCenters,
                                                    textField: "Value",
                                                    dataItemKey: "Key"
                                                }}
                                            />
                                        </div>
                                        <CheckboxField
                                            fieldName="notifyCreatorOnStateChange"
                                            label={resource("ppp-checkboxsendserviceemailsheader")} />

                                        <div className="request-form-recaptcha">
                                            {showRecaptchaV2 && (
                                                <ReCAPTCHA
                                                    sitekey={this.props.clientSettings.reCaptchaV2SiteKey}
                                                    onChange={this.props.setReCaptchaV2Token}
                                                    theme="light"
                                                    size='normal'
                                                />
                                            )}
                                        </div>

                                        <div className="submit-area-container">
                                            <Button
                                                themeColor="primary"
                                                type="button"
                                                onClick={() => this.handleFormSubmit(formikProps.submitForm)}
                                                className="submit-button"
                                                disabled={formikProps.isSubmitting || !formikProps.isValid || !uploadReady || (showRecaptchaV2 && !recaptchaV2Token)}>
                                                {resource("common-send")}
                                            </Button>
                                        </div>
                                    </Ripple>
                                </Form>
                            </div>
                            <CustomCookieConsent />
                        </div>
                    );
                }}
            </Formik>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestFormSubmit: (payload: any) => {
            dispatch(requestFormSubmitAsyncAction(payload));
        },
        loadRequestFormData: (payload: any) => {
            dispatch(loadRequestFormDataAsyncAction(payload));
        },
        reCaptchaV3Verification: () => {
            dispatch(requestFormReCaptchaV3VerificationAsyncAction());
        },
        setReCaptchaV2Token: (token: string | null) => {
            dispatch(setRequestFormReCaptchaV2TokenAsyncAction(token));
        }
    };
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        requestFormData: requestFormSelectors.requestFormData(state),
        requestFormGuid: requestFormSelectors.requestFormGuid(state),
        clientSettings: commonSelectors.clientSettings(state),
        reCaptchaV3Result: commonSelectors.reCaptchaV3Result(state),
        reCaptchaV2Token: commonSelectors.reCaptchaV2Token(state),
        isMandatoryText: resource("commonprocess-exchangeratevaluevalidationmessage"),
        fieldMaxLengthText: resource("common-fieldmaxlengthtext"),
        fieldContainsIllegalCharacters: resource("common-fieldcontainsillegalcharacters"),
        ...ownProps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestForm);