import axios, { AxiosRequestConfig } from 'axios';
import { Language, ClientSettings, ClientUrlParams } from '../../common/model/commonModel';

const noCacheOptions = {
    headers: {
        'Cache-Control': 'no-cache, no-store' // Axios add pragma: no-cache header itself by some rule
    }
}

class CommonService {
    getOptions = (): AxiosRequestConfig => {
        return {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Cache-Control': 'no-cache, no-store' // Axios add pragma: no-cache header itself by some rule
            }
        };
    }

    getId = () => {
        return window.location.search;
    }

    getLanguages = async (): Promise<Language[]> => {
        return (await axios.get(`/api/Data/GetLanguages`, noCacheOptions)).data;
    }

    getClientSettings = async (): Promise<ClientSettings> => {
        return (await axios.get(`/api/Data/GetClientSettings`, noCacheOptions)).data;
    }

    getUrlParams = async (mode: string): Promise<ClientUrlParams> => {
        return (await axios.get(`/api/Data/ParseUrlParam/${this.getId()}&mode=${mode}`, this.getOptions().headers)).data;
    }

    validateReCaptcha = async (token: string): Promise<boolean> => {
        return (await axios.get(`/api/Data/VerifyReCaptchaV3Token?token=${token}`, noCacheOptions)).data;
    }
}

export default new CommonService();