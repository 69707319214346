import { FileUploadTexts, IntlMessages } from "./model/commonModel";

export const localization = (fileUploadTexts: FileUploadTexts): IntlMessages => {
    return {
        messages: {
            "upload": {
                "cancel": fileUploadTexts.Cancel,
                "clearSelectedFiles": fileUploadTexts.ClearSelectedFiles,
                "dropFilesHere": fileUploadTexts.DropFilesHere,
                "headerStatusUploaded": fileUploadTexts.HeaderStatusUploaded,
                "headerStatusUploading": fileUploadTexts.HeaderStatusUploading,
                "invalidFileExtension": fileUploadTexts.InvalidFileExtension,
                "invalidFiles": fileUploadTexts.InvalidFiles,
                "invalidMaxFileSize": fileUploadTexts.InvalidMaxFileSize,
                "invalidMinFileSize": fileUploadTexts.InvalidMinFileSize,
                "remove": fileUploadTexts.Remove,
                "retry": fileUploadTexts.Retry,
                "select": fileUploadTexts.Select,
                "uploadSelectedFiles": fileUploadTexts.UploadSelectedFiles,
                "total": fileUploadTexts.Total,
                "files": fileUploadTexts.Files,
                "maximumFileCountText": fileUploadTexts.MaximumFileCountText
            }
        }
    };
}