import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../common/components/Footer"
import ModuleRequestForm from "./ModuleRequestForm"
import Loading from "../../common/components/Loading";
import { loadModuleRequestFormDataAsyncAction } from "../sagas/moduleRequestFormSaga";
import * as moduleRequestFormSelectors from '../../moduleservicerequest/selectors/moduleRequestFormSelectors';
import FullscreenMessage from "../../common/components/FullscreenMessage";
import { ApplicationState } from "../../store/configureStore";

export function ModuleRequestFormWrapper()
{
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadModuleRequestFormDataAsyncAction());
    }, []);

    const isIncompleteDefinitions = useSelector((state: ApplicationState) => moduleRequestFormSelectors.requestFormData(state))?.isIncompleteDefinitions ?? true;
    const isFetching = useSelector((state: any) => state.common.isFetching);
    const isSubmitting = useSelector((state: any) => state.common.isSubmitting);

    return (
        <>
            {
                isFetching || isSubmitting ?
                <Loading />
                    :
                    isIncompleteDefinitions ?
                        <FullscreenMessage
                            iconName="exclamation-circle"
                            resourceKey={"ppp-portlet-incomplete-definitions"} />
                    :
                    <>
                        <ModuleRequestForm />
                        <Footer />
                    </>
            }
        </>
    );
}