import { Ripple } from '@progress/kendo-react-ripple';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/configureStore';
import * as commonSelectors from '../selectors/commonSelectors';
import { resource } from '../utils';

export interface ICustomCookieConsent {
    privacyPolicyUrl: string;
    cookieConsentText: string;
    cookieConsentReadMore: string;
    cookieConsentConfirm: string;
}

function CustomCookieConsent(props: ICustomCookieConsent) {
    return (    
        <Ripple>
            <CookieConsent
                containerClasses="cookie-consent k-d-flex k-fixed theme-bg"
                contentClasses="cookie-consent__content theme-color"
                buttonWrapperClasses="cookie-consent__button-wrapper"
                buttonClasses="k-button k-button-lg k-button-solid k-rounded-full submit-button cookie-consent__button"
                buttonText={props.cookieConsentConfirm}
                disableStyles
                disableButtonStyles
            >
                <div className="cookie-consent__content-message">
                    {props.cookieConsentText}
                </div>
                <a
                    className="cookie-consent__privacy-link k-relative"
                    href={props.privacyPolicyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {props.cookieConsentReadMore}
                </a>
            </CookieConsent>
        </Ripple>
    );
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        privacyPolicyUrl: commonSelectors.commonData(state).PrivacyPolicyUrl,
        cookieConsentText: resource("common-cookieconsent"),
        cookieConsentReadMore: resource("common-cookieconsentreadmore"),
        cookieConsentConfirm: resource("common-cookieconsentconfirm"),
        ...ownProps
    }
};

export default connect(mapStateToProps)(CustomCookieConsent);