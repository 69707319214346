import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestFormData } from '../model/requestFormModel';

export interface RequestFormState {
    requestFormData: RequestFormData | null;
    requestFormGuid: string;
}

export const initialState: RequestFormState = {
    requestFormData: null,
    requestFormGuid: ''
};

export const requestFormSlice = createSlice({
    name: 'requestForm',
    initialState: initialState,
    reducers: {
        setRequestFormData: (state, action: PayloadAction<RequestFormData>) => {
            state.requestFormData = action.payload;
        },
        setRequestFormGuid: (state, action: PayloadAction<string>) => {
            state.requestFormGuid = action.payload;
        }
    }
})

export const requestFormReducer = requestFormSlice.reducer;
export const requestFormActions = requestFormSlice.actions;
