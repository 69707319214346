import React from 'react';
import { Field, FieldProps } from 'formik';
import { TextBox } from "@progress/kendo-react-inputs";
import { FloatingLabel } from '@progress/kendo-react-labels';
import { ConditionalShow } from "./ConditionalShow";
import { Validator, isValid } from './Validator';
import { FieldInfoText } from './FieldInfoText';

export const getLabel = (label: string, isRequired?: boolean) => (label + " " || " ") + (isRequired ? ' *' : ''); // " " because label cant be empty, otherwise the component styles break

export interface TextBoxProps {
    fieldName: string;
    label: string;
    isRequired?: boolean;
    hidden?: boolean;
    disabled?: boolean;
    type?: "text" | "password" | "email";
    className?: string;
    title?: string;
    dir?: string;
    placeholder?: string;
    isPlaceholderEnabled?: boolean;
    info?: string;
}


class TextBoxField extends React.Component<TextBoxProps> {
    constructor(props: any) {
        super(props);
    }

    onBlur = (event: any, fieldProps: FieldProps<any>) => {
        fieldProps.form.handleBlur(event);
    }

    onChange = (event: any, fieldProps: FieldProps<any>) => {
        fieldProps.form.setFieldValue(this.props.fieldName, event.value);
    }

    render() {
        const label = getLabel(this.props.label, this.props.isRequired);

        return (
            <ConditionalShow {...this.props}>
                <Field name={this.props.fieldName}>
                    {(fieldProps: FieldProps<any>) => {
                        const valid = isValid(this.props.fieldName, fieldProps);
                        const placeholder = this.props.isPlaceholderEnabled
                            ? this.props.placeholder
                            : "";
                        return (
                            <div className="form__input-container k-relative">
                                <FloatingLabel
                                    label={label}
                                    editorId={fieldProps.field.name}
                                    editorValue={fieldProps.field.value}
                                    editorValid={valid}>
                                    <TextBox
                                        {...fieldProps.field}
                                        id={this.props.fieldName}
                                        value={fieldProps.field.value ?? ""}
                                        valid={valid}

                                        onChange={
                                            (event: any) => this.onChange(event, fieldProps)
                                        }

                                        dir={this.props.dir || "ltr"}
                                        placeholder={placeholder}
                                        title={this.props.title}
                                        onBlur={(event: any) => this.onBlur(event, fieldProps)}
                                    />
                                    <Validator
                                        valid={valid}
                                        fieldProps={fieldProps}
                                        fieldName={this.props.fieldName} />
                                </FloatingLabel>
                                <FieldInfoText
                                    valid={valid}
                                    fieldProps={fieldProps}
                                    info={this.props.info} />
                            </div>
                        );
                    }}
                </Field>
            </ConditionalShow>
        );
    }
}

export default TextBoxField;