import axios from 'axios';
import { ClientResponse } from '../../common/model/commonModel';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { RequestFormData } from '../model/requestFormModel';
import commonService from '../../common/service/commonService';

class RequestService {
    save = async (values: any, captchaToken: string | null, languageCode: string): Promise<ClientResponse> => {
        const serviceRequestMessage = {
            ServiceRequestData: this.createServiceData(values),
            // CustomerGuid is filled from session in backend
            Files: values.files.map((file: UploadFileInfo) => { return { uid: file.uid, name: file.name } }),
            RequestFormGuid: values.requestFormGuid,
            CaptchaToken: captchaToken,
            AcceptLanguage: languageCode
        };

        return (await axios.post(`/api/ServiceRequest/Save${commonService.getId()}`, serviceRequestMessage, commonService.getOptions())).data;
    }

    getServiceRequestSettings = async (languageCode: string, formGuid: string): Promise<RequestFormData> => {
        let options = commonService.getOptions();
        options.headers["Accept-Language"] = languageCode;

        return (await axios.get(`/api/ServiceRequest/GetServiceRequestSettings${commonService.getId()}&formGuid=${formGuid}`, options)).data;
    }

    createServiceData = (values: any) => {
        return {
            Service: {
                // ObjectId is filled from session in backend
                Request: values.description,
                NotifyCreatorOnStateChange: values.notifyCreatorOnStateChange,
                ServiceAreaId: values.selectedServiceArea ? values.selectedServiceArea.Key : values.selectedAdviceType ? values.selectedAdviceType.ServiceAreaId : null,
                AdviceTypeId: values.selectedAdviceType ? values.selectedAdviceType.AdviceTypeId : null,
                UrgencyId: values.selectedUrgency ? values.selectedUrgency.Key : null,
                AdditionalInfo: values.additionalInfo
            },
            Customer: {
                PhoneNumber: values.phone,
                Email: values.email,
                Name: values.name,
                CostCenterId: values.selectedCostCenter ? values.selectedCostCenter.Key : null
            },
        };
    }

}

export default new RequestService();