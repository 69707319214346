import { put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as commonSelectors from '../../common/selectors/commonSelectors';
import { ClientResponse, ClientSettings, Language } from '../../common/model/commonModel';
import feedbackService from '../service/feedbackService';
import commonService from '../../common/service/commonService';
import { initLogging, fillShortLanguage } from '../../common/sagas/commonSaga';
import { commonActions, createCommonData } from '../../common/reducers/commonReducers';
import { FeedbackFormData } from '../model/feedbackModel';
import { feedbackFormActions } from '../reducers/feedbackFormReducers';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { takeEvery } from "redux-saga/effects";

export const loadFeedbackFormDataAsyncAction = createAction<Language | undefined>('FEEDBACK_FORM_DATA_FETCH');
export const feedbackFormSubmitAsyncAction = createAction<FeedbackFormData>('FEEDBACK_FORM_SUBMIT');

export const feedbackFormSagas = {
    watchLoadFeedbackFormDataAsyncAction: takeEvery(loadFeedbackFormDataAsyncAction, loadFeedbackFormDataSaga),
    watchFeedbackFormSubmitAsyncAction: takeEvery(feedbackFormSubmitAsyncAction, feedbackFormSubmitSaga)
};

export function* loadFeedbackFormDataSaga({ payload: selectedLanguage }: PayloadAction<Language | undefined>) {
    yield put(commonActions.fetchStart());

    // update language if given
    if (selectedLanguage) {
        selectedLanguage = fillShortLanguage(selectedLanguage);
        yield put(commonActions.selectLanguage(selectedLanguage));
    } else {
        selectedLanguage = yield select(commonSelectors.selectedLanguage);
    }

    const clientSettings: ClientSettings = yield call(commonService.getClientSettings);
    yield call(initLogging, clientSettings.logApiKey);

    const feedbackFormData: FeedbackFormData = yield call(feedbackService.getFeedbackFormData, selectedLanguage!.Code);

    yield put(feedbackFormActions.setFeedbackFormData(feedbackFormData));
    yield put(commonActions.setCommonData(createCommonData(feedbackFormData)));

    yield put(commonActions.fetchEnd());
}

export function* feedbackFormSubmitSaga({ payload: formData }: PayloadAction<FeedbackFormData>) {
    yield put(commonActions.fetchStart());

    const saveResponse: ClientResponse = yield call(feedbackService.save, formData);
    if (!saveResponse.isValid) {
        yield put(push('/error'));
        return;
    }

    const location: Location = yield select(commonSelectors.location);

    yield put(push({
        pathname: '/feedbackSent',
        search: location.search // preserve query parameters
    } as any));

    yield put(commonActions.fetchEnd());
}