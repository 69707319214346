import React from 'react';
import { ConditionalShow } from "./ConditionalShow";
import { FieldProps, Field } from "formik";
import { DropDownListProps, DropDownList } from "@progress/kendo-react-dropdowns";
import { Validator, isValid } from './Validator';
import { getLabel } from './TextBoxField';
import { FieldInfoText } from './FieldInfoText';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from "@progress/kendo-data-query";

interface IDropDownListFieldProps {
    label: string;
    dropdownProps: DropDownListProps;
    fieldName: string;
    hidden?: boolean;
    isRequired?: boolean;
    info?: string;
    filterable?: boolean;
}

const VIRTUALIZATION_DEFAULT_PAGESIZE = 50;

export function DropDownListField(props: IDropDownListFieldProps) {
    const label = getLabel(props.label, props.isRequired);

    return props.filterable
        ? DropDownListFieldWithFiltering(props, label)
        : DropDownListFieldWithoutFiltering(props, label);
}

function DropDownListFieldWithFiltering(props: IDropDownListFieldProps, label: string) {
    const allData = props.dropdownProps.data ?? [];
    const total = allData?.length;
    const filteredData = React.useRef(allData.slice());
    const [state, setState] = React.useState({
        skip: 0,
        total: total,
        subsetData: allData.slice(0, VIRTUALIZATION_DEFAULT_PAGESIZE),
    });

    const onFilterChange = (event: { filter: FilterDescriptor | CompositeFilterDescriptor; }) => {
        filteredData.current = filterBy(allData.slice(), event.filter);
        const data = filteredData.current.slice(0, VIRTUALIZATION_DEFAULT_PAGESIZE);
        setState({
            subsetData: data,
            skip: 0,
            total: filteredData.current.length,
        });
    };

    const pageChange = (event: { page: { skip: any; take: any; }; }) => {
        const skip = event.page.skip;
        const take = event.page.take;
        const newSubsetData = filteredData.current?.slice(skip, skip + take);
        setState({
            ...state,
            subsetData: newSubsetData,
            skip: skip
        });
    };

    return (
        <ConditionalShow {...props}>
            <Field name={props.fieldName}>
                {(fieldProps: FieldProps<any>) => {
                    const valid = isValid(props.fieldName, fieldProps);
                    return (
                        <div className="form__input-container k-relative">
                            <DropDownList
                                {...fieldProps.field}
                                label={label}
                                valid={valid}
                                data={state.subsetData}
                                textField={props.dropdownProps.textField}
                                dataItemKey={props.dropdownProps.dataItemKey}
                                virtual={{
                                    total: state.total,
                                    pageSize: VIRTUALIZATION_DEFAULT_PAGESIZE,
                                    skip: state.skip,
                                }}
                                onPageChange={pageChange}
                                filterable={props.filterable}
                                onFilterChange={onFilterChange} />
                            <Validator
                                valid={valid}
                                fieldProps={fieldProps}
                                fieldName={props.fieldName}
                                className="form__warning--dropdown" />
                            <FieldInfoText
                                valid={valid}
                                fieldProps={fieldProps}
                                info={props.info} />
                        </div>
                    );
                }}
            </Field>
        </ConditionalShow>
    );
}

function DropDownListFieldWithoutFiltering(props: IDropDownListFieldProps, label: string) {
    return (
        <ConditionalShow {...props}>
            <Field name={props.fieldName}>
                {(fieldProps: FieldProps<any>) => {
                    const valid = isValid(props.fieldName, fieldProps);
                    return (
                        <div className="form__input-container k-relative">
                            <DropDownList
                                {...fieldProps.field}
                                label={label}
                                valid={valid}
                                {...props.dropdownProps} />
                            <Validator
                                valid={valid}
                                fieldProps={fieldProps}
                                fieldName={props.fieldName}
                                className="form__warning--dropdown" />
                            <FieldInfoText
                                valid={valid}
                                fieldProps={fieldProps}
                                info={props.info} />
                        </div>
                    );
                }}
            </Field>
        </ConditionalShow>
    );
}