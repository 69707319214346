import React from 'react';
import { FeedbackQuestion } from '../model/feedbackModel';
import { Field, FieldProps } from 'formik';
import { RadioButton } from '@progress/kendo-react-inputs';

export interface FeedbackQuestionProps {
    fieldName: string;
    feedbackQuestion: FeedbackQuestion;
}

const onClick = (e: any, fieldProps: FieldProps<any>) => {
    fieldProps.form.setFieldValue(fieldProps.field.name, parseInt(e.target.value));
    e.stopPropagation();
}

export const Question: React.SFC<FeedbackQuestionProps> = (props) => (
    <Field name={props.fieldName}>
        {(fieldProps: FieldProps<any>) =>
            <div className="feedback-question">
                <div className="feedback-question__title">
                    {props.feedbackQuestion.Title}
                </div>
                <div className="feedback-answers k-flex-col">
                    {props.feedbackQuestion.Answers.map((item, index) => (
                        <div
                            className="feedback-answer radio-wrapper k-d-inline-flex k-align-items-center"
                            key={index}>
                            <RadioButton
                                name={fieldProps.field.name}
                                checked={fieldProps.field.value === item.QuestionAnswerId}
                                onClick={e => onClick(e, fieldProps)}
                                value={item.QuestionAnswerId}
                                label={item.Answer}
                            />
                        </div>
                    ))}
                </div>
            </div>
        }
    </Field>
);


export default Question;