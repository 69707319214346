import axios from 'axios';
import { ClientResponse } from '../../common/model/commonModel';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import commonService from '../../common/service/commonService';
import { ModuleRequestFormData } from "../model/ModuleRequestFormData";
import { ModuleServiceRequestMessage } from "../model/ModuleServiceRequestMessage";

export const saveModuleServiceRequest = async (values: any, captchaToken: string | null, languageCode: string): Promise<ClientResponse> => {
    const serviceRequestMessage: ModuleServiceRequestMessage = {
        serviceRequestData: {
            ...values,
            categoryId: values.selectedCategoryType?.id,
            serviceAreaId: values.selectedServiceArea?.id,
            additionalFields: [...values?.globalAdditionalFields, ...values?.additionalFieldsByCategory],
            documents: values.files.map((doc: UploadFileInfo) => {
                return {
                    id: undefined,
                    fileId: doc.uid,
                    filename: doc.name,
                    sizeInBytes: doc.size ?? 0,
                    extension: doc.extension ?? "",
                };
            })
        },
        requestFormGuid: values.requestFormGuid,
        captchaToken: captchaToken,
        acceptLanguage: languageCode
    };

    return (await axios.post(`/api/ModuleServiceRequest/Save${commonService.getId()}`, serviceRequestMessage, commonService.getOptions())).data;
}

export const getModuleServiceRequestFormData = async (languageCode: string, formGuid: string): Promise<ModuleRequestFormData> => {
    let options = commonService.getOptions();
    options.headers["Accept-Language"] = languageCode;

    return (await axios.get(`/api/ModuleServiceRequest/GetServiceRequestFormData${commonService.getId()}&formGuid=${formGuid}`, options)).data;
}