import { FieldArray, FieldArrayRenderProps } from 'formik';
import { useEffect } from 'react';
import { ObjectSchema } from "yup";
import { ModuleRequestFormModel } from '../model/ModuleRequestFormData';
import { CategoryViewModel } from "../model/CategoryViewModel";
import { ModuleServiceRequestAdditionalFields } from './ModuleServiceRequestAdditionalFields';
import { AdditionalFieldType } from '../model/AdditionalFieldViewModel';
import { replaceFieldArrayValues } from '../../common/utils';

const additionalFieldsByCategoryName = "additionalFieldsByCategory";
export function ModuleServiceRequestAdditionalFieldsByCategory(props: { 
    form: ModuleRequestFormModel, 
    values: CategoryViewModel | null, 
    isMandatoryText: string,
    fieldMaxLengthText: string,
    validationSchema: ObjectSchema<any>, 
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, 
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
}) {  
    return (
        <>
            <FieldArray name={additionalFieldsByCategoryName} render={(fieldArrayRenderProps: FieldArrayRenderProps) => {
                return (
                    <>
                        <ServiceRequestEditAdditionalFieldsByCategoryState 
                            fieldArrayRenderProps={fieldArrayRenderProps}
                            values={props?.values}
                            form={props.form}
                            setFieldValue={props.setFieldValue}
                            setFieldTouched={props.setFieldTouched}
                        />

                        <ModuleServiceRequestAdditionalFields 
                            values={props?.values?.additionalFields}
                            fieldName={additionalFieldsByCategoryName}
                            isMandatoryText={props.isMandatoryText}
                            fieldMaxLengthText={props.fieldMaxLengthText}
                            validationSchema={props.validationSchema}
                        />
                    </>
                );
            }} />
        </>
    );
}

export function ServiceRequestEditAdditionalFieldsByCategoryState(props: { 
    form: ModuleRequestFormModel, 
    values: CategoryViewModel | null, 
    fieldArrayRenderProps: FieldArrayRenderProps,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, 
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
}) {
    const additionalFields = props?.values?.additionalFields ?? [];

    useEffect(() => {
        // Clear values and set touched information to false
        props.form[additionalFieldsByCategoryName]?.forEach((additionalField: { type: { id: AdditionalFieldType; }; }, index: any) => {
            let field: string = "";
            let value: string | [] = "";

            if (additionalField.type?.id === AdditionalFieldType.Text) {
                field = `${additionalFieldsByCategoryName}.${index}.textValue`;
                value = "";
            } else if (additionalField.type?.id === AdditionalFieldType.MultiSelectOptions) {
                field = `${additionalFieldsByCategoryName}.${index}.optionValue`;
                value = [];
            }
                
            props.setFieldValue(field, value);
            props.setFieldTouched(field, false);
        });
    }, [props?.form["selectedCategoryType"]]);

    useEffect(() => {
        replaceFieldArrayValues(props.fieldArrayRenderProps, additionalFields, props.form[additionalFieldsByCategoryName]?.length);
    }, [additionalFields]);

    return null;
}