import { load } from '@progress/kendo-react-intl';

//import likelySubtags from "cldr-core/supplemental/likelySubtags.json"
//import currencyData from 'cldr-core/supplemental/currencyData.json';
//import weekData from 'cldr-core/supplemental/weekData.json';

export default function internalizationLoader(kendoCode: string) {
    let intlImport: Promise<any>;

    switch (kendoCode) {
        case "fi":
            intlImport = import(/* webpackChunkName: "internalization-fi" */ './languages/fi');
            break;
        case "de":
            intlImport = import(/* webpackChunkName: "internalization-de" */ './languages/de');
            break;
        case "da":
            intlImport = import(/* webpackChunkName: "internalization-da" */ './languages/da');
            break;
        case "ru":
            intlImport = import(/* webpackChunkName: "internalization-ru" */ './languages/ru');
            break;
        case "sv":
            intlImport = import(/* webpackChunkName: "internalization-sv" */ './languages/sv');
            break;
        case "et":
            intlImport = import(/* webpackChunkName: "internalization-et" */ './languages/et');
            break;
        case "lt":
            intlImport = import(/* webpackChunkName: "internalization-lt" */ './languages/lt');
            break;
        case "lv":
            intlImport = import(/* webpackChunkName: "internalization-lv" */ './languages/lv');
            break;
        case "zh":
            intlImport = import(/* webpackChunkName: "internalization-zh" */ './languages/zh');
            break;
        case "en-GB":
            intlImport = import(/* webpackChunkName: "internalization-en-GB" */ './languages/en-GB');
            break;
        case "sv-FI":
            intlImport = import(/* webpackChunkName: "internalization-sv-FI" */ './languages/sv-FI');
            break;
        case "ar-AE":
            intlImport = import(/* webpackChunkName: "internalization-ar-AE" */ './languages/ar-AE');
            break;
        case "nb":
            intlImport = import(/* webpackChunkName: "internalization-nb" */ './languages/nb');
            break;
        default:
            throw new Error(`internalizationLoader: unsupported language ${kendoCode} failed to load`);
    }

    intlImport.then(({ default: internalizationData }) => load(/*likelySubtags, currencyData, weekData,*/ ...internalizationData));

    return intlImport;
}