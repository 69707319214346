import React from 'react';
import { classNames } from '@progress/kendo-react-common';
import { orderBy as _orderBy } from "lodash";
import { IFilePreview } from '../../common/model/commonModel';
import '../../scss/circularprogress.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export interface FilePreviewProps {
    filePreviews: IFilePreview[];
    onRemove: (file: IFilePreview) => void;
}

class FilePreview extends React.Component<FilePreviewProps> {
    render() {
        const orderedFilePreviews = _orderBy(this.props.filePreviews, ["isImage"], ["desc"]);

        return (
            <div className="file-preview-content">
                {
                    orderedFilePreviews.map((file: IFilePreview) => (
                        <div
                            className={classNames({
                                'file-preview-item': true,
                                'k-relative': true,
                                'k-d-inline-flex': true,
                                'file-uploading': file.isUploading
                            })}
                            key={file.uid}>
                            {
                                file.isImage ?
                                    <img
                                        src={file.rawFile}
                                        title={file.name}
                                        alt={file.name}
                                        className="image-preview"
                                    /> :
                                    <div
                                        className="file-preview"
                                        title={file.name}>
                                        {file.name}
                                    </div>
                            }
                            <span
                                className="file-remove k-absolute k-d-inline-flex k-align-items-center k-justify-content-center"
                                onClick={() => this.props.onRemove(file)}
                                title={file.name}>
                                <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                            </span>
                            {
                                file.isUploading ?
                                    <div className="file-uploading-spinner k-absolute k-d-flex k-align-items-center k-justify-content-center">
                                        <svg className="circular-progress circular-progress--small k-relative">
                                            <circle
                                                className="circular-progress__path"
                                                cx="17" cy="17"
                                                r="14"
                                                fill="none"
                                                strokeWidth="4"
                                                strokeMiterlimit="10" />
                                        </svg>
                                    </div>
                                : null
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default FilePreview;