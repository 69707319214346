import React from 'react';
import { ConditionalShow } from "./ConditionalShow";
import { FieldProps, getIn, Field } from "formik";
import { MultiSelect, MultiSelectProps } from "@progress/kendo-react-dropdowns";
import { Validator, isValid } from './Validator';
import { FieldInfoText } from './FieldInfoText';
import * as _ from "lodash";
import { ObjectSchema } from 'yup';
import { AnyObject, TypeOfShape, AssertsShape } from 'yup/lib/object';

export const isRequiredField = (validationSchema: ObjectSchema<any, AnyObject, TypeOfShape<any>, AssertsShape<any>>, name: string | string[]) =>
    !!getIn(validationSchema.describe().fields, name)?.tests.find(
        (test: { name: string; }) => test.name === 'required'
    )

interface MultiSelectFieldProps {
    label: string;
    dropdownProps: MultiSelectProps;
    fieldName: string;
    hidden?: boolean;
    isRequired?: boolean;
    info?: string;
    placeholder?: string;
    validationSchema: ObjectSchema<any>,
    title?: string;
}

export function MultiSelectField(props: MultiSelectFieldProps) {
    return (
        <ConditionalShow {...props}>
            <Field name={props.fieldName}>
                {(fieldProps: FieldProps<any>) => {
                    const valid = isValid(props.fieldName, fieldProps);
                    const overrides: MultiSelectProps = {
                        placeholder: props.placeholder ?? isRequiredField(props.validationSchema, props.fieldName) ? props.placeholder : undefined,
                        valid: valid,
                        value: getIn(fieldProps.form.values, props.fieldName),
                        popupSettings: {
                            animate: {
                                openDuration: 200,
                                closeDuration: 100
                            },
                            className: props.dropdownProps.className + "-popup",
                            height: "auto",
                        },
                    }

                    const multiSelectPropsOverrides = { ...fieldProps.field, ...overrides, ...props.dropdownProps };
                    multiSelectPropsOverrides.onChange = (event: any) => {
                        // override multiselect value change event for formik => will set value and then call GMMultiSelectField onChange event
                        // without this, if onChange has function defined in GMMultiSelectField, value will not be changed for formik
                        fieldProps.form.setFieldValue(props.fieldName, [...event.value]);
                        if (props.dropdownProps.onChange) {
                            props.dropdownProps.onChange(event);
                        }
                    }

                    return (
                        <div className="form__input-container k-relative" title={props.title}>
                            <MultiSelect
                                label={props.label}
                                valid={valid}
                                {...multiSelectPropsOverrides} />
                            <Validator
                                valid={valid}
                                fieldProps={fieldProps}
                                fieldName={props.fieldName}
                                className="form__warning--dropdown" />
                            <FieldInfoText
                                valid={valid}
                                fieldProps={fieldProps}
                                info={props.info} />
                        </div>
                    );
                }}
            </Field>
        </ConditionalShow>
    );
}