import React from 'react';
import { connect } from 'react-redux';
import { Location, Action } from 'history';
import { History } from 'history';
import * as commonSelectors from '../../common/selectors/commonSelectors';
import { Language } from '../../common/model/commonModel';
import Loading from '../../common/components/Loading';
import { Localization } from '../../common/components/Localization';
import FeedbackFormWrapper from './FeedbackFromWrapper';
import { initAsyncAction } from '../../common/sagas/commonSaga';
import { commonActions } from '../../common/reducers/commonReducers';
import { ApplicationState } from '../../store/configureStore';

export interface FeedbackProps {
    isInitialFetching: boolean;
    initAsync: () => void;
    resetAppState: () => void;
    history: History;
    selectedLanguage: Language;
}

class Feedback extends React.Component<FeedbackProps> {
    constructor(props: FeedbackProps) {
        super(props);

        this.updatePastLocations = this.updatePastLocations.bind(this);
    }

    private updatePastLocations(location: Location, action: Action) {
        switch (action) {
            case 'POP': {
                this.props.resetAppState();
                break;
            }
            default:
        }
    }

    componentDidMount() {
        this.props.initAsync();

        this.props.history.listen(this.updatePastLocations);
    }

    render() {
        return (
            this.props.isInitialFetching
                ?
                <Loading />
                :
                <Localization kendoCode={this.props.selectedLanguage.KendoCode}>
                    <FeedbackFormWrapper />
                </Localization>
            );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        initAsync: () => {
            dispatch(initAsyncAction("feedback"));
        },
        resetAppState: () => {
            dispatch(commonActions.resetAppState());
        }
    };
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        isInitialFetching: state.common.isInitialFetching,
        selectedLanguage: commonSelectors.selectedLanguage(state),
        ...ownProps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
