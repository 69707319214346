import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { History } from 'history';
import '../../scss/circularprogress.scss';
import { Button } from '@progress/kendo-react-buttons';
import { Ripple } from '@progress/kendo-react-ripple';
import { resource } from '../utils';

export interface FullscreenMessageProps {
    message?: string;
    iconName?: any;
    iconClassName?: string;
    backButtonText?: string;
    history?: History;
    showSpinner?: boolean;
    resourceKey?: string;
}

const FullscreenMessage: React.SFC<FullscreenMessageProps> = (props) => (
    <div className="fullscreen-layout k-absolute theme-bg">
        <div className="fullscreen-layout__content k-absolute theme-color k-d-flex-col k-align-items-center k-justify-content-center">
                {
                    props.showSpinner === true
                        ?
                    <svg className="circular-progress k-relative">
                            <circle
                            className="circular-progress__path"
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                strokeWidth="4"
                                strokeMiterlimit="10" />
                        </svg>
                        :
                        <FontAwesomeIcon
                            icon={props.iconName}
                            className={props.iconClassName}
                            size="7x" />
                }
                <div className="fullscreen-layout__content-message">
                {props.resourceKey ? resource(props.resourceKey) : props.message}

            </div>
            {
                props.backButtonText ?
                    <Ripple className="fullscreen-layout__back-button-ripple">
                        <Button
                            themeColor={null}
                            fillMode="outline"
                            className="fullscreen-layout__back-button theme-color"
                            onClick={() => { props.history!.goBack(); }}
                        >
                            {props.backButtonText}
                        </Button>
                    </Ripple>
                : null
            }
            </div>
            
        </div>
);

export default FullscreenMessage;