import { put, call, select } from 'redux-saga/effects';
import requestService from '../service/requestService';
import { push } from 'connected-react-router';
import { localization } from '../../common/localizationMessages';
import { loadMessages } from '@progress/kendo-react-intl';
import * as commonSelectors from '../../common/selectors/commonSelectors';
import { Language, ClientSettings, ClientResponse, IntlMessages, AdviceType } from '../../common/model/commonModel';
import { commonActions, createCommonData } from '../../common/reducers/commonReducers';
import { v4 as uuidv4 } from 'uuid';
import { fillShortLanguage, generateRecaptchaToken, initLogging } from '../../common/sagas/commonSaga';
import { RequestFormData } from '../model/requestFormModel';
import { takeEvery } from "redux-saga/effects";
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { requestFormActions } from '../reducers/requestFormReducers';
import commonService from '../../common/service/commonService';

export const loadRequestFormDataAsyncAction = createAction<Language | undefined>('REQUEST_FORM_DATA_FETCH');
export const requestFormSubmitAsyncAction = createAction<RequestFormData>('REQUEST_FORM_SUBMIT');
export const requestFormReCaptchaV3VerificationAsyncAction = createAction('REQUEST_FORM_RECAPTCHA_V3_VERIFICATION');
export const setRequestFormReCaptchaV2TokenAsyncAction = createAction<string | null>('REQUEST_FORM_RECAPTCHA_V2_SET');

export const requestFormSagas = {
    watchLoadRequestFormDataAsyncAction: takeEvery(loadRequestFormDataAsyncAction, loadRequestFormDataSaga),
    watchRequestFormSubmitAsyncAction: takeEvery(requestFormSubmitAsyncAction, requestFormSubmitSaga),
    watchRequestFormReCaptchaV3VerificationAsyncAction: takeEvery(requestFormReCaptchaV3VerificationAsyncAction, requestFormReCaptchaV3VerificationSaga),
    watchSetRequestFormReCaptchaV2TokenSagaAsyncAction: takeEvery(setRequestFormReCaptchaV2TokenAsyncAction, setRequestFormReCaptchaV2TokenSaga)
};

export function* loadRequestFormDataSaga({ payload: selectedLanguage }: PayloadAction<Language | undefined>) {
    yield put(commonActions.fetchStart());

    // guid is used by temporary file storage
    const formGuid: string = uuidv4();
    yield put(requestFormActions.setRequestFormGuid(formGuid));
    const clientSettings: ClientSettings = yield select(commonSelectors.clientSettings);
    yield call(initLogging, clientSettings.logApiKey, formGuid);

    // update language if given
    if (selectedLanguage) {
        selectedLanguage = fillShortLanguage(selectedLanguage);
        yield put(commonActions.selectLanguage(selectedLanguage));
    } else {
        selectedLanguage = yield select(commonSelectors.selectedLanguage);
    }

    const requestFormData: RequestFormData = yield call(requestService.getServiceRequestSettings, selectedLanguage!.Code, formGuid);
    //Advice type might be linked to 0-n service area
    //To avoid ui glitches we must assure uniqueness of user selection and therefore we generate guid for each row
    generateGuidsForAdviceTypes(requestFormData.AdviceTypes);

    let loc: IntlMessages = yield call(localization, requestFormData.FileUploadTexts);
    yield put(commonActions.setLocalizedMessages(loc));

    yield call(loadMessages, loc.messages, selectedLanguage!.Code);

    yield put(requestFormActions.setRequestFormData(requestFormData));
    yield put(commonActions.setCommonData(createCommonData(requestFormData)));

    yield put(commonActions.fetchEnd());
}

export function* requestFormSubmitSaga({ payload: formData }: PayloadAction<RequestFormData>) {
    yield put(commonActions.fetchStart());

    const selectedLanguage: Language = yield select(commonSelectors.selectedLanguage);

    const saveResponse: ClientResponse = yield call(requestService.save, formData, formData.CaptchaToken, selectedLanguage.Code);
    yield put(commonActions.resetCaptchaTokens());

    if (!saveResponse.isValid) {
        yield put(push('/error'));
        return;
    }

    const location: Location = yield select(commonSelectors.location);

    yield put(push({
        pathname: '/requestSent',
        search: location.search // preserve query parameters
    } as any));

    yield put(commonActions.fetchEnd());
}


export function* requestFormReCaptchaV3VerificationSaga() {
    const clientSettings: ClientSettings = yield select(commonSelectors.clientSettings);
    let token: string = yield call(generateRecaptchaToken, clientSettings.reCaptchaV3SiteKey);

    let isValidToken: boolean = yield call(commonService.validateReCaptcha, token);
    yield put(commonActions.setCaptchaV3Token({ Token: token, Valid: isValidToken, ShowReCaptchaV2: !isValidToken }));
}

export function* setRequestFormReCaptchaV2TokenSaga({ payload: token }: PayloadAction<string | null>) {
    yield put(commonActions.setCaptchaV2Token({ Token: token }));
}

function generateGuidsForAdviceTypes(adviceTypes: Array<AdviceType>): void {
    adviceTypes && adviceTypes.forEach((adviceType) => {
        adviceType.AdviceTypeGuid = uuidv4();
    });
}