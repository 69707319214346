import React from 'react';
import { History } from 'history';
import '../../scss/circularprogress.scss';
import { connect } from 'react-redux';
import Loading from '../../common/components/Loading';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FullscreenMessage from '../../common/components/FullscreenMessage';
import { resource } from '../../common/utils';
import { ApplicationState } from '../../store/configureStore';

export interface FeedbackSentProps {
    isFetching: boolean;
    message: string;
    history?: History;
}

class FeedbackSent extends React.Component<FeedbackSentProps> {
    render() {
        return (
            this.props.isFetching
                ?
                <Loading />
                :
                <FullscreenMessage
                    iconName={faCheckCircle}
                    {...this.props} />
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        isFetching: state.common.isFetching,
        message: resource("ppp-feedbackthanks"),
        ...ownProps
    }
};

export default connect(mapStateToProps)(FeedbackSent);