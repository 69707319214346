import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

export function initLocalization(language: string) {
    return i18n
        .use(initReactI18next)
        .use(HttpApi)
        .init({
            backend: {
                loadPath: "/locales/{{lng}}/{{ns}}"
            },
            lng: language,
            fallbackLng: "en-GB",
            load: "currentOnly",
            interpolation: {
                escapeValue: false
            }
        });
}