import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Ripple } from '@progress/kendo-react-ripple';
import { Formik, FormikProps, FormikHelpers, Form } from "formik";
import { FeedbackFormData } from '../model/feedbackModel';
import * as feedbackSelectors from '../selectors/feedbackSelectors';
import Question from './Question';
import { formatDate } from '@progress/kendo-intl'; 
import * as commonSelectors from '../../common/selectors/commonSelectors';
import { Language } from '../../common/model/commonModel';
import TitleBar from '../../common/components/TitleBar';
import CustomCookieConsent from '../../common/components/CustomCookieConsent';
import TextAreaField from '../../common/components/Field/TextAreaField';
import { resource } from '../../common/utils';
import { feedbackFormSubmitAsyncAction, loadFeedbackFormDataAsyncAction } from '../sagas/feedbackFormSaga';
import { ApplicationState } from '../../store/configureStore';

export interface FeedbackFormProps {
    feedbackFormData: FeedbackFormData;
    feedbackFormSubmit: (values: any) => void;
    loadFeedbackFormDataAsync: (language: Language) => void;
    selectedLanguage: Language;
}

class FeedbackForm extends React.Component<FeedbackFormProps> {
    render() {
        let data = this.props.feedbackFormData;

        return (
            <Formik
                enableReinitialize={false}
                initialValues={{
                    freeText: ""
                }}
                onSubmit={(values: any, formikHelpers: FormikHelpers<any>): void => {
                    this.props.feedbackFormSubmit(values);
                }}
            >
                {(formikProps: FormikProps<any>) => {
                    return (
                        <div className="main">
                            <div className="form-container">
                                <TitleBar title={resource("ppp-feedbacktitle")} objectName={this.props.feedbackFormData.CodedObject.DisplayName} reloadFormDataAction={this.props.loadFeedbackFormDataAsync} />
                                <Form className="form">
                                    <Ripple>
                                        <div className="feedback-form">
                                            <div className="feedback-form__title">
                                                {resource("ppp-portlet-column-description")}
                                            </div>
                                            <div className="feedback-form__description">
                                                {data.RequestText}
                                            </div>
                                            <div className="feedback-form__title">
                                                {resource("ppp-servicefinished")}
                                            </div>
                                            <div className="feedback-form__finished-date">
                                                {data.ServiceRequestFinishedDate != null
                                                    ? formatDate(data.ServiceRequestFinishedDate,
                                                        { datetime: "short" },
                                                        this.props.selectedLanguage.KendoCode)
                                                    : "-"
                                                }
                                            </div>
                                            {data.IsAlreadySubmitted ?
                                                <div className="feedback-form__already-submitted">
                                                    {resource("ppp-feedbackalreadyexists")}
                                                </div>
                                                : null
                                            }
                                        </div>

                                        {data.Questions != null ?
                                            data.Questions.map((item, index) => (
                                                <Question key={index}
                                                    fieldName={item.QuestionId.toString()}
                                                    feedbackQuestion={item} />
                                            ))
                                            : null
                                        }

                                        {!data.IsAlreadySubmitted ?
                                            <>
                                                <div className="feedback-form__open-feedback">
                                                    <TextAreaField
                                                        fieldName="freeText"
                                                        label={resource("ppp-qualityfeedbackfreetexttitle")}
                                                    />
                                                </div>
                                                <div className="form__submit-area-container k-d-flex k-justify-content-end">
                                                    <Button
                                                        themeColor={null}
                                                        rounded={"full"}
                                                        type="submit"
                                                        className="submit-button"
                                                        size="large"
                                                        disabled={formikProps.isSubmitting}>
                                                        {resource("ppp-savefeedback")}
                                                    </Button>
                                                </div>
                                            </>
                                            : null
                                        }
                                    </Ripple>
                                </Form>
                            </div>

                            <CustomCookieConsent />
                        </div>
                    );
                }}
            </Formik>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        feedbackFormSubmit: (payload: any) => {
            dispatch(feedbackFormSubmitAsyncAction(payload));
        },
        loadFeedbackFormDataAsync: (payload: any) => {
            dispatch(loadFeedbackFormDataAsyncAction(payload));
        }
    };
};

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        selectedLanguage: commonSelectors.selectedLanguage(state),
        feedbackFormData: feedbackSelectors.feedbackFormData(state),
        ...ownProps
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);