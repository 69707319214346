import React from 'react';
import './scss/app.scss';
import { Route, Switch } from 'react-router';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import FullscreenMessage from './common/components/FullscreenMessage';
import { connect } from 'react-redux';
import { ConnectedRouterProps } from 'connected-react-router';
import ServiceRequest from './common/components/ServiceRequest';
import RequestSent from './common/components/RequestSent';
import FeedbackSent from './feedback/components/FeedbackSent';
import Feedback from './feedback/components/Feedback';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { getTextBrightness } from './common/utils';

const errorMessage = "Verify you are using an up-to-date address.";

const App: React.SFC = () => {
    let mainBackgroundColor = new URLSearchParams(window.location.search).get("mainBackgroundColor");
    mainBackgroundColor = mainBackgroundColor && decodeURIComponent(mainBackgroundColor);
    const themeBackground = mainBackgroundColor ?? "rgba(237, 129, 19, 1)";
    const themeColor = getTextBrightness(themeBackground) > 180 ? '#000000' : '#ffffff';
    const themeBrightness = getTextBrightness(themeBackground).toString();
    const themeBorderColor = getTextBrightness(themeBackground) < 180 ? themeBackground : 'rgba(0,0,0, .87)';

    document.body.style.setProperty("--themeBackground", themeBackground);
    document.body.style.setProperty("--themeColor", themeColor);
    document.body.style.setProperty("--themeBrightness", themeBrightness);
    document.body.style.setProperty("--themeBorderColor", themeBorderColor);

    return (
        <Switch>
            <Route exact path='/' component={ServiceRequest} />
            <Route path='/feedback' component={Feedback} />
            <Route path='/requestSent' render={(routeProps: ConnectedRouterProps) => <RequestSent {...routeProps} />} />
            <Route path='/feedbackSent' render={(routeProps: ConnectedRouterProps) => <FeedbackSent {...routeProps} />} />
            <Route path='/error' render={(routeProps: ConnectedRouterProps) => <FullscreenMessage message={errorMessage} iconName={faExclamationCircle} {...routeProps} />} />
            <Route path='*' render={(routeProps: ConnectedRouterProps) => <FullscreenMessage message={errorMessage} iconName={faExclamationCircle} {...routeProps} />} />
        </Switch>
    );
}

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        ...ownProps
    }
};

export default connect(mapStateToProps)(App);