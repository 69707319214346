import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../common/sagas/rootSaga';
import { connectRouter, routerMiddleware, push, RouterState } from 'connected-react-router';
import { RaygunStatic } from 'raygun4js';
import { feedbackFormReducer, FeedbackFormState } from '../feedback/reducers/feedbackFormReducers';
import { moduleRequestFormReducer, ModuleRequestFormState } from '../moduleservicerequest/reducers/moduleRequestFormReducers';
import { requestFormReducer, RequestFormState } from '../servicerequest/reducers/requestFormReducers';
import { commonReducer, CommonState } from '../common/reducers/commonReducers';

declare var window: any;
declare var Raygun: RaygunStatic;

export interface ApplicationState {
    common: CommonState;
    requestForm: RequestFormState;
    moduleRequestForm: ModuleRequestFormState;
    feedbackForm: FeedbackFormState;
    router: RouterState;
}

export default function configureStore(history: any) {
    const sagaMiddleware = createSagaMiddleware({
        onError: (error: any) => {
            console.error(error);
            Raygun.send(error); // no error here event if apikey is empty
            // this level saga-tree is terminated, only redux can be used, but app state is unpredictable
            store.dispatch(push('/error'));
        }
    });

    const middleware = [
        routerMiddleware(history),
        sagaMiddleware
    ];

    const rootReducer = combineReducers({
        common: commonReducer,
        requestForm: requestFormReducer,
        moduleRequestForm: moduleRequestFormReducer,
        feedbackForm: feedbackFormReducer,
        router: connectRouter(history)
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    var store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...middleware))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
